import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import {
  DashboardPage,
  AddRemovePlayerPage,
  PlayerColorsPage,
  ProfileSettingPageNew,
} from "./usersubpages";
import {
  AddRemoveSitePage,
  AddRemoveUserPage,
  StackConvertorPage,
  ServerStatusPage,
} from "./adminsubpages";
import { GetPlayerColors } from "../helpers/supabase";
import { Page404 } from "pages/404";

const AppContent = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.user);

  useEffect(() => {
    if (user) {
      GetPlayerColors(user[0].id)
        .then((data) => {
          if (data.length === 1) {
            dispatch({ type: "set_player_color", payload: data[0] });
          }
        })
        .catch((error) => {});
    }
  }, [dispatch, user]);

  const GetRoutes = () => {
    if (user && user[0].type === "0") {
      return (
        <Routes>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/profilesetting" element={<ProfileSettingPageNew />} />
          <Route path="/addremoveplayer" element={<AddRemovePlayerPage />} />
          <Route path="/playercolors" element={<PlayerColorsPage />} />
          <Route path="/addremoveuser" element={<AddRemoveUserPage />} />
          <Route path="/addremovesite" element={<AddRemoveSitePage />} />
          <Route path="/stakeconvertor" element={<StackConvertorPage />} />
          <Route path="/serverstatus" element={<ServerStatusPage />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/profilesetting" element={<ProfileSettingPageNew />} />
          <Route path="/addremoveplayer" element={<AddRemovePlayerPage />} />
          <Route path="/playercolors" element={<PlayerColorsPage />} />
          <Route path="*" exact element={<Page404 />} />
        </Routes>
      );
    }
  };

  return (
    <CContainer fluid className="easyseat-align-center-container">
      <Suspense fallback={<CSpinner color="primary" />}>{GetRoutes()}</Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
