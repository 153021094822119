import TreeView, { flattenTree } from "react-accessible-treeview";
import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import cx from "classnames";
import FontAwesome from "react-fontawesome";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
  GetUserAccess,
  InsertUserAccess,
  UpdateUserAccess,
} from "helpers/admin/supabase";
import {
  CButtonGroup,
  CLoadingButton,
  CSpinner,
  CFormSelect,
  CRow,
  CCol,
} from "@coreui/react-pro";
import { GetUserFormSelectData } from "helpers/admin/transform";
import { ShowSuccessToast } from "helpers/toast-alert";
import { AMERICASCARDROOM, S888POKER, WINAMAX } from "constant/constant";

const AccessCheckTreeviewWidget = () => {
  
  const siteUnionClubs = useSelector((state) => state.siteUnionClubState.data);
  const [expanded, setExpanded] = useState([]);
  const [nodes, SetNodes] = useState([]);
  const [selected, SetSelected] = useState([]);

  const [loading, SetLoading] = useState(true);
  const [updating, SetUpdating] = useState(false);

  const ini_users = useSelector((state) => state.usersChangeState.data);
  const [users, SetUsers] = useState([]);
  const [user, SetUser] = useState("");
  let SiteListWithNoUnion = [];
  const getSiteListHasNoUnion = ()=>{
    let ret = [];
    siteUnionClubs.forEach(ele=>{
      if(ele['site_unions'].length === 0){
        ret.push(ele['site_name']);
      }
    })
    return ret;
  }
  // const SiteListWithNoUnion = [WINAMAX, S888POKER, AMERICASCARDROOM];


  let selectedNodes = null;

  useEffect(() => {
    if (ini_users !== null && ini_users.length !== 0) {
      let tusers = GetUserFormSelectData(ini_users);
      SetUsers(tusers);
      SetUser(tusers[0]["value"]);
      SiteListWithNoUnion = getSiteListHasNoUnion();
      ini_nodes = GetNodesForNoCheckBoxTreeView(siteUnionClubs);
      let ftnodex = flattenTree(ini_nodes);
      ini_expandednodes = GetExpandedValue(ftnodex);
      SetNodes(ftnodex);
      setExpanded(ini_expandednodes);
      InitUIUserAccessData(tusers[0]["value"], ftnodex);
    }
  }, [siteUnionClubs, ini_users]);

  const UserSelectOnChange = (event) => {
    SetUser(event.target.value);
    InitUIUserAccessData(event.target.value, nodes);
  };

  const GetNodesForNoCheckBoxTreeView = (siteUnionsClubs) => {
    let tmnodes = { name: "", children: [] };
    siteUnionsClubs.forEach((val) => {
      let tsitenode = { name: "", children: [] };
      tsitenode.name = val["site_name"];
      val["site_unions"].forEach((val1) => {
        let tunionnode = { name: "", children: [] };
        tunionnode.name = val1["union_name"];
        val1["union_clubs"]["club_name"].forEach((val2) => {
          let tclubnode = {};
          tclubnode.name = val2;
          tunionnode["children"].push(tclubnode);
        });
        tsitenode["children"].push(tunionnode);
      });
      tmnodes.children.push(tsitenode);
    });
    return tmnodes;
  };

  const GetExpandedValue = (nodes) => {
    let expandedValue = [];
    nodes.forEach((val) => {
      if (val["children"].length !== 0) {
        expandedValue.push(val["id"]);
      }
    });
    return expandedValue;
  };

  const InitUIUserAccessData = (user_id, nodes) => {
    SetLoading(true);
    GetUserAccess(user_id).then((ret) => {
      if (ret && ret.length !== 0) {
        let tchecked = JSON.parse(ret[0]["access_site"]);
        selectedNodes = GetSelectedValue(tchecked, nodes);
        SetSelected(selectedNodes);
        SetLoading(false);
      } else {
        let data = {
          user_nid: user_id,
          access_site: JSON.stringify([]),
        };
        InsertUserAccess(data).then((ret) => {
          if (ret) {
            selectedNodes = GetSelectedValue([], nodes);
            SetSelected(selectedNodes);
          }
          SetLoading(false);
        });
      }
    });
  };

  let ini_expandednodes = [];
  let ini_nodes = {};

  const GetSelectedValue = (access_site, nodes) => {
    let ret = [];
    access_site.forEach((ele) => {
      let vlist = ele.split("|");
      if (vlist.length == 1) {
        nodes.forEach((ele2) => {
          if (ele2.name === ele) {
            if (!ret.includes(ele2.id)) {
              ret.push(ele2.id);
            }
          }
        });
      } else {
        nodes.forEach((ele2) => {
          if (ele2.name === vlist[2]) {
            if (!ret.includes(ele2.id)) {
              ret.push(ele2.id);
            }
          }
        });
      }
    });
    return ret;
  };

  const UpdateUserAccessInfoButonOnClick = () => {
    let ret = [];
    SetUpdating(true);
    selected.forEach((id) => {
      ret.push(GetSelectedClubString(id));
    });
    if (selected.length == 0) {
      SetUpdating(false);
      return;
    }
    let data = {
      user_nid: user,
      access_site: JSON.stringify(ret),
    };
    UpdateUserAccess(data).then((val) => {
      if (val) {
        ShowSuccessToast("Successfully Updated.");
      }
      SetUpdating(false);
    });
  };

  const GetNodeElementByID = (id) => {
    let ret = null;

    nodes.forEach((ele) => {
      if (ele.id === id) {
        ret = ele;
      }
    });
    return ret;
  };

  const GetSelectedClubString = (id) => {
    let ele_club = GetNodeElementByID(id);
    if (ele_club !== null) {
      if (ele_club.parent == 0) {
        return `${ele_club.name}`;
      }
      let ele_union = GetNodeElementByID(ele_club.parent);
      if (ele_union !== null) {
        let ele_site = GetNodeElementByID(ele_union.parent);
        return `${ele_site.name}|${ele_union.name}|${ele_club.name}`;
      }
    }
  };

  const DefaultButonOnClick = () => {
    SetSelected([]);
  };

  const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
      baseClass,
      { [`${baseClass}--closed`]: !isOpen },
      { [`${baseClass}--open`]: isOpen },
      className
    );
    return <IoMdArrowDropright className={classes} />;
  };

  const CheckBoxIcon = ({ variant, ...rest }) => {
    switch (variant) {
      case "all":
        return <FaCheckSquare {...rest} />;
      case "none":
        return <FaSquare {...rest} />;
      case "some":
        return <FaMinusSquare {...rest} />;
      default:
        return null;
    }
  };

  const onSelect = (e) => {
    if (e.element.children.length > 0) return;
    let nselected = [];
    // let pnode = GetNodeElementByID(e.element.parent);
    nselected = [...selected];

    // nselected = nselected.filter(function (item) {
    //   return !pnode.children.includes(item);
    // });

    if (e.isSelected) {
      if (!nselected.includes(e.element.id)) {
        nselected.push(e.element.id);
      }
    } else {
      nselected = [...selected];
      nselected = nselected.filter(function (item) {
        return item !== e.element.id;
      });
    }
    SetSelected(nselected);
  };

  const GetElementName = (element, name, level, isSelected, isHalfSelected) => {
    let isChecked = false;

    if (isSelected || isHalfSelected) {
      isChecked = true;
    }

    if (!isChecked) {
      if (level === 2) {
        element.children.forEach((ele) => {
          if (!isChecked) {
            isChecked = selected.includes(ele);
          }
        });
      }

      if (level === 1) {
        element.children.forEach((ele) => {
          let subele = GetNodeElementByID(ele);
          subele.children.forEach((ele1) => {
            if (!isChecked) {
              isChecked = selected.includes(ele1);
            }
          });
        });
      }
    }

    if (level === 1) {
      SiteListWithNoUnion = getSiteListHasNoUnion();
      if (SiteListWithNoUnion.includes(name)) {
        return (
          <span
            className="name"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              borderBottom: "1px solid #ffffff54",
            }}
          >
            <FontAwesome name="dice"></FontAwesome> {name}{" "}
            {isChecked && (
              <FontAwesome
                name="square-check"
                style={{ color: "green" }}
              ></FontAwesome>
            )}
          </span>
        );
      } else {
        return (
          <span
            className="name"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              borderBottom: "1px solid #ffffff54",
              marginLeft: "26px",
            }}
          >
            <FontAwesome name="dice"></FontAwesome> {name}{" "}
            {isChecked && (
              <FontAwesome
                name="square-check"
                style={{ color: "green" }}
              ></FontAwesome>
            )}
          </span>
        );
      }
    } else if (level == 2) {
      return (
        <span className="name" style={{ fontSize: "16px", fontWeight: "500" }}>
          <FontAwesome name="globe"></FontAwesome> {name}{" "}
          {isChecked && (
            <FontAwesome
              name="square-check"
              style={{ color: "green" }}
            ></FontAwesome>
          )}
        </span>
      );
    } else {
      return (
        <span className="name" style={{ fontSize: "14px" }}>
          <FontAwesome name="users"></FontAwesome> {name}{" "}
          {isChecked && (
            <FontAwesome
              name="square-check"
              style={{ color: "green" }}
            ></FontAwesome>
          )}
        </span>
      );
    }
  };

  const GetElementCheckBox = (
    name,
    level,
    isSelected,
    isHalfSelected,
    handleSelect
  ) => {
    if (level === 3 || SiteListWithNoUnion.includes(name)) {
      return (
        <CheckBoxIcon
          className="checkbox-icon"
          onClick={(e) => {
            handleSelect(e);
            e.stopPropagation();
          }}
          variant={isHalfSelected ? "some" : isSelected ? "all" : "none"}
        />
      );
    }
  };

  const GetElementArrow = (name, isBranch, isExpanded) => {
    let valmarginleft = 0;
    // if(["Winamax"].includes(name)){
    //   valmarginleft = 10
    // }
    if (isBranch) {
      return (
        <ArrowIcon
          style={{ marginLeft: `${valmarginleft}px` }}
          isOpen={isExpanded}
        />
      );
    } else {
      return <span style={{ marginLeft: `${21 + valmarginleft}px` }}></span>;
    }
  };

  const GetMainElement = () => {
    if (nodes.length > 0 && !loading) {
      return (
        <div className="checkbox">
          <TreeView
            data={nodes}
            aria-label="Checkbox tree"
            multiSelect
            propagateSelect
            propagateSelectUpwards
            togglableSelect
            onSelect={(e) => onSelect(e)}
            expandedIds={expanded}
            selectedIds={selected}
            nodeRenderer={({
              element,
              isBranch,
              isExpanded,
              isSelected,
              isHalfSelected,
              getNodeProps,
              level,
              handleSelect,
              handleExpand,
            }) => {
              return (
                <div
                  {...getNodeProps({ onClick: handleExpand })}
                  style={{ marginLeft: 70 * (level - 1) }}
                >
                  {GetElementArrow(element.name, isBranch, isExpanded)}
                  {GetElementCheckBox(
                    element.name,
                    level,
                    isSelected,
                    isHalfSelected,
                    handleSelect
                  )}
                  {GetElementName(
                    element,
                    element.name,
                    level,
                    isSelected,
                    isHalfSelected
                  )}
                </div>
              );
            }}
          ></TreeView>
        </div>
      );
    } else {
      return (
        <div className="d-flex p-1">
          <CSpinner color="info" variant="grow" className="m-1" />
          <CSpinner color="info" variant="grow" className="m-1" />
          <CSpinner color="info" variant="grow" className="m-1" />
        </div>
      );
    }
  };

  return (
    <div>
      <CRow>
        <CCol lg={6}>
          <CFormSelect
            className="mb-2"
            options={users}
            value={user}
            onChange={UserSelectOnChange}
          ></CFormSelect>
        </CCol>
        <CCol>
          <CButtonGroup role="group" style={{ width: "100%" }}>
            <CLoadingButton
              color="info"
              loading={updating}
              onClick={UpdateUserAccessInfoButonOnClick}
            >
              Update
            </CLoadingButton>
            <CLoadingButton
              color="dark"
              timeout={300}
              onClick={DefaultButonOnClick}
            >
              Default
            </CLoadingButton>
          </CButtonGroup>
        </CCol>
      </CRow>
      {GetMainElement()}
    </div>
  );
};
export default React.memo(AccessCheckTreeviewWidget);
