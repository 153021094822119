import React, { useState } from "react";
import {
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CCard,
  CCardHeader,
  CCol,
  CCardBody,
  CButton,
  CLoadingButton,
} from "@coreui/react-pro";
import {
  ShowFailToast,
  ShowSuccessToast,
  ShowWarningToast,
} from "helpers/toast-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  GetNewDefaultProfile,
  InsertProfile,
  SupaProfileToJSonSettingObject,
} from "helpers";
import LoadingScreen from "react-loading-screen";

const CreateProfileDlg = () => {
  const user_nid = useSelector((state) => state.login.user[0].id);
  const siteUnionsClubs = useSelector((x) => x.siteUnionClubState.data);
  const profiles = useSelector((x) => x.profileState.profiles);
  const dispatch = useDispatch();

  const [profilename, SetProfilename] = useState("");
  const [creating, SetCreating] = useState(false);

  const IsValidProfileName = () => {
    if (profilename === "") return false;
    profiles.forEach((val) => {
      if (
        val["name"].toLowerCase() === profilename.toLowerCase() ||
        profilename.toLowerCase() === "default"
      )
        return false;
    });
    return true;
  };

  const CancelBtnOnClick = () => {
    dispatch({ type: "set", showCreateNewProfileDlg: false });
    dispatch({ type: "set", disableProfile: false });
  };

  const CreateBtnOnClick = () => {
    SetCreating(true);
    if (IsValidProfileName()) {
      let newProfile = GetNewDefaultProfile(
        user_nid,
        profilename,
        siteUnionsClubs
      );

      InsertProfile(newProfile).then((val) => {
        if (val === 1) {
          ShowSuccessToast(
            `Successfully added new profile '${profilename}' just.`
          );
          let dps = [...profiles];
          dps.push(SupaProfileToJSonSettingObject(newProfile));
          dispatch({
            type: "set_profiles",
            payload: dps,
          });
          dispatch({ type: "set", showCreateNewProfileDlg: false });
          dispatch({ type: "set", disableProfile: false });
          dispatch({ type: "set_current_profile_index", payload: dps.length - 1 })
        } else {
          ShowFailToast(`Failed adding new profile '${profilename}' just.`);
        }
        SetCreating(false);
      });
    } else {
      ShowWarningToast(
        "Please type profile name correctly. Or the profile's name already maybe be existed."
      );
      SetCreating(false);
    }
  };


  const GetLoadingScreen = () => {
    return (
      <LoadingScreen
        loading={creating}
        bgColor="rgba(255,255,255,0.1)"
        spinnerColor="#000000"
      >
        <div></div>
      </LoadingScreen>
    );
  };

  const getDOMElement = () => {
    return (
      <CCardBody>
        <CInputGroup className="flex-nowrap mb-2">
          <CInputGroupText id="addon-wrapping">Profile Name:</CInputGroupText>
          <CFormInput
            type="text"
            value={profilename}
            onChange={(a) => {
              SetProfilename(a.target.value);
            }}
            required
          />
        </CInputGroup>
        <div>
          <CLoadingButton
            className="mt-2"
            color="success"
            loading={creating}
            style={{
              width: "30%",
              marginLeft: "5%",
            }}
            onClick={CreateBtnOnClick}
          >
            Create
          </CLoadingButton>
          <CButton
            className="mt-2"
            color="danger"
            style={{
              width: "30%",
              marginLeft: "28%",
            }}
            onClick={CancelBtnOnClick}
          >
            Cancel
          </CButton>
        </div>
      </CCardBody>
    );
  };

  return (
    <CCol lg={12}>
      <CCard className="mb-2">
        <CCardHeader>
          <strong>Create New Profile</strong>
        </CCardHeader>
        {getDOMElement()}
      </CCard>
      <hr />
      {GetLoadingScreen()}
    </CCol>
  );
};
export default React.memo(CreateProfileDlg);
