import { CMultiSelect } from "@coreui/react-pro";
import { GetGameTypeObjectFromMulSel } from "helpers";
import React, { useRef } from "react";

export default function GameTypeMultiSelector({
  disableProfile,
  options,
  UpdateGameTypeProfileSetting,
}) {
  const multiSelectRef = useRef(null);

  const handleChange = (a) => {
    UpdateGameTypeProfileSetting(GetGameTypeObjectFromMulSel(a));
  };

  const handleClickOutside = (event) => {
    if(!event.target.classList.contains('form-multi-select-option-with-checkbox') && !event.target.classList.contains('form-multi-select-all')){
      if (!multiSelectRef.current.classList.contains('show')) {
        multiSelectRef.current.classList.add("show");
        multiSelectRef.current.setAttribute("aria-expanded", true);
      } else {
        multiSelectRef.current.classList.remove("show");
        multiSelectRef.current.setAttribute("aria-expanded", false);
      }
    }
  };

  return (
    <div>
      <div className="easyseat-gametype-multiselector" style={{ cursor: "pointer" }} onClick={handleClickOutside}>
        <CMultiSelect
          style={{ cursor: "pointer" }}
          ref={multiSelectRef}
          options={options}
          disabled={disableProfile}
          selectionType="tags"
          optionsStyle="checkbox"
          selectAll={true}
          selectAllLabel="Select all"
          placeholder="Please select the game types..."
          search={false}
          onChange={handleChange}
          // onHide={() => {
          //   setIsShow(false);
          // }}
        />
      </div>
    </div>
  );
}
