import React, { useEffect, useState, useRef } from "react";
import {
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CCard,
  CCardHeader,
  CRow,
  CCol,
  CCardBody,
  CLoadingButton,
  CFormSelect,
  CButton,
} from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "react-loading-screen";
import {
  GetPlayer,
  GetPlayerBynID,
  InsertNewPlayer,
  DeletePlayer,
  DeletePlayerByID,
} from "../../helpers/supabase";
import { history } from "helpers";
import { GetSites, GetClubs, GetUnionBySiteAndClub } from "helpers/trasform";
import { PLAYER_TYPES_OPTIONS, REGULAR } from "constant/constant";
import FontAwesome from "react-fontawesome";
import {
  ShowSuccessToast,
  ShowFailToast,
  ShowWarningToast,
} from "../../helpers/toast-alert";
import ConfirmRemovePlayerDlg from "./ConfirmRemovePlayerDlg";
import SiteFormSelect from "./customizecomponent/SiteFormSelect";

import PlayerSearchForm from "./customizecomponent/PlayerSearchForm";
import PlayerDetailsForm from "./customizecomponent/PlayerDetailsForm";
import { ConvertLowercase } from "helpers/utils";

const AddRemovePlayerPage = () => {
  const dispatch = useDispatch();

  const siteUnionClubs = useSelector((state) => state.siteUnionClubState.data);

  if (siteUnionClubs.length === 0) {
    return (
      <CCard className="mb-2 mt-2">
        <CCardBody
          style={{ background: "darkred", borderRadius: "6px", color: "white" }}
        >
          <h4>
            <FontAwesome
              name="triangle-exclamation"
              size="lg"
              style={{ color: "yellow" }}
            ></FontAwesome>
            You can never any clubs now. Please have a contact with
            adminstrator.{" "}
          </h4>
        </CCardBody>
      </CCard>
    );
  }
  const sites = GetSites(siteUnionClubs);
  const [showConfirmDialog, SetShowConfirmDialog] = useState(false);

  let inisite = null;
  let iniclubs = null;
  let iniclub = null;
  let iniunion = null;
  let iniptitleAdd = null;
  let iniptitleDel = null;

  if (sites.length !== 0) {
    inisite = sites[0]["value"];
    iniclubs = GetClubs(siteUnionClubs, inisite);

    if (iniclubs.length !== 0) {
      iniclub = iniclubs[0]["value"];
      iniptitleAdd = "ID";
      iniptitleDel = "ID";
      iniunion = GetUnionBySiteAndClub(siteUnionClubs, inisite, iniclub);
    } else {
      iniptitleAdd = "Player Name";
      iniptitleDel = "Player Name";
    }
  }

  const [playertype1, SetPlayertype1] = useState(REGULAR);
  const [ptitleAdd, SetPtitleAdd] = useState(iniptitleAdd);
  const [playername1, SetPlayername1] = useState("");
  const [nid1, SetNid1] = useState("");

  const [adding, SetAdding] = useState(false);
  const [deleting, SetDeleting] = useState(false);
  const [site1, Setsite1] = useState(inisite);
  const [clubs1, Setclubs1] = useState(iniclubs);
  const [club1, Setclub1] = useState(iniclub);
  const [union1, SetUnion1] = useState(iniunion);
  const [site2, Setsite2] = useState(inisite);
  const [clubs2, Setclubs2] = useState(iniclubs);
  const [club2, Setclub2] = useState(iniclub);
  const [union2, SetUnion2] = useState(iniunion);

  useEffect(() => {
    if (history.location.pathname.includes("dashboard")) {
      dispatch({ type: "set", showProfile: true });
    } else {
      dispatch({ type: "set", showProfile: false });
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const addSiteSelectOnChange = (event) => {
    let value = undefined;
    if (event.target === undefined) {
      value = event.value;
    } else {
      value = event.target.value;
    }

    Setsite1(value);
    let clubs = GetClubs(siteUnionClubs, value);
    Setclubs1(clubs);
    if (clubs.length === 0) {
      Setclub1(null);
      SetPtitleAdd("Player Name");
      SetUnion1(null);
    } else {
      Setclub1(clubs[0]["value"]);
      let union = GetUnionBySiteAndClub(
        siteUnionClubs,
        value,
        clubs[0]["value"]
      );
      SetUnion1(union);
      SetPtitleAdd("ID");
    }
    SetNid1("");
  };

  const addPlayerTypeOnChange = (event) => {
    SetPlayertype1(event.target.value);
  };

  const AddPlayerBtnOnClick = () => {
    var user = {};
    if (ptitleAdd !== "ID") {
      user = {
        nickname: playername1,
        nid: ConvertLowercase(playername1),
        playertype: playertype1,
        site: site1,
        setbyuser: "1",
      };
    } else {
      user = {
        nickname: playername1,
        playertype: playertype1,
        nid: nid1,
        site: site1,
        setbyuser: "1",
      };
    }
    SetAdding(true);
    if (user.nid === "") {
      SetAdding(false);
      ShowWarningToast("Please type the player name.");
      return;
    }
    if (ptitleAdd === "ID") {
      GetPlayerBynID(user).then((ret) => {
        if (ret.length !== 0) {
          ShowWarningToast(
            `'${user.nickname}' already is existed! Please check again.`
          );
          ClearAddPlayerForm();
        } else {
          InsertNewPlayer(user).then((ret) => {
            if (ret === 1) {
              ShowSuccessToast(
                `Adding Success! '${user.nickname}' has been added just now.`
              );
              ClearAddPlayerForm();
            } else {
              ShowFailToast("Adding new player Failed! Please check again.");
              ClearAddPlayerForm();
            }
          });
        }
        SetAdding(false);
        setSearchTerm("");
        SetNid1("");
        SetPlayername1("");
      });
    } else {
      GetPlayer(user).then((ret) => {
        if (ret.length !== 0) {
          ShowWarningToast(
            `'${user.nickname}' already is existed! Please check again.`
          );
          ClearAddPlayerForm();
        } else {
          InsertNewPlayer(user).then((ret) => {
            if (ret === 1) {
              ShowSuccessToast(
                `Adding Success! '${user.nickname}' has been added just now.`
              );
              ClearAddPlayerForm();
            } else {
              ShowFailToast("Adding new player Failed! Please check again.");
              ClearAddPlayerForm();
            }
          });
        }
        SetAdding(false);
        setSearchTerm("");
        SetNid1("");
        SetPlayername1("");
      });
    }
  };

  const ClearAddPlayerForm = () => {
    SetPlayername1("");
  };

  const GetConfirmRemovePlayerDlg = () => {
    if (showConfirmDialog) {
      return (
        <ConfirmRemovePlayerDlg
          GetConfirmRemoveDlgResult={GetConfirmRemoveDlgResult}
        />
      );
    }
  };

  const GetConfirmRemoveDlgResult = (isyes) => {
    if (!isyes) {
      SetShowConfirmDialog(false);
      return;
    }
    SetShowConfirmDialog(false);
    DeletePlayerByID(selectedPlayer).then((ret) => {
      if (ret === 1) {
        ShowSuccessToast(
          `Deleting Success! '${selectedPlayer.nickname}' has been deleted just now.`
        );
        setSelectedPlayer(null);
      } else {
        ShowFailToast("Deleting this player Failed! Please check again.");
      }
      setSearchTerm("");
    });
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [showAddNewPlayerForm, setShowAddNewPlayerForm] = useState(false);
  const handlePlayerSelect = (player) => {
    setSelectedPlayer(player);
  };
  const AddNewPlayerBtnOnClick = () => {
    setShowAddNewPlayerForm(!showAddNewPlayerForm);
  };

  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowAddNewPlayerForm(false); // Close dropdown if clicked outside
      SetPtitleAdd("ID");
    }
  };

  const DelPlayerBtnOnClick = () => {
    if (selectedPlayer) {
      SetShowConfirmDialog(true);
    }
  };

  const GetNidTypeField = () => {
    if (ptitleAdd === "ID") {
      return (
        <CInputGroup className="flex-nowrap mb-2" style={{ marginTop: "22px" }}>
          <CInputGroupText id="addon-wrapping">ID:</CInputGroupText>
          <CFormInput
            type="text"
            value={nid1}
            onChange={(ret) => {
              SetNid1(ret.target.value);
            }}
          />
        </CInputGroup>
      );
    }
  };

  const GetPlaceholder = () => {
    if (ptitleAdd === "ID") {
      return "Optional";
    } else {
      return "";
    }
  };

  const getAddNewPlayerForm = () => {
    if (showAddNewPlayerForm) {
      return (
        <CRow ref={dropdownRef}>
          <CCol lg={12}>
            <CCard className="mb-2 mt-2">
              <CCardHeader>
                <strong>Add New Player</strong>
              </CCardHeader>
              <CCardBody className="easyseat-text-align-left">
                <SiteFormSelect
                  label="Site:"
                  options={sites}
                  value={site1}
                  onChange={addSiteSelectOnChange}
                />
                <CInputGroup
                  className="flex-nowrap mb-2"
                  style={{ marginTop: "22px" }}
                >
                  <CInputGroupText id="addon-wrapping">
                    Player Name:
                  </CInputGroupText>
                  <CFormInput
                    type="text"
                    value={playername1}
                    placeholder={GetPlaceholder()}
                    onChange={(ret) => {
                      if (ptitleAdd !== "ID") {
                        SetNid1(ret.target.value);
                      }
                      SetPlayername1(ret.target.value);
                    }}
                    required
                  />
                </CInputGroup>
                {GetNidTypeField()}
                <CFormSelect
                  className="mb-2"
                  label="Player Type:"
                  options={PLAYER_TYPES_OPTIONS}
                  value={playertype1}
                  onChange={addPlayerTypeOnChange}
                ></CFormSelect>
                <CLoadingButton
                  className="mt-3"
                  color="info"
                  loading={adding}
                  style={{ marginLeft: "60%", width: "40%" }}
                  onClick={AddPlayerBtnOnClick}
                >
                  <strong>Add</strong>
                </CLoadingButton>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      );
    }
  };

  return (
    <div className="align-items-center" style={{ maxWidth: "800px" }}>
      {GetConfirmRemovePlayerDlg()}
      <CRow>
        <CCol lg={12}>
          <CCard className="mb-2 mt-2">
            <CCardHeader>
              <strong>Manage Notes</strong>
            </CCardHeader>
            <CCardBody className="easyseat-text-align-left">
              <CRow>
                <CCol lg={8}>
                  <CButton
                    color="success"
                    style={{ width: "100%", marginBottom: "-14px" }}
                    onClick={AddNewPlayerBtnOnClick}
                  >
                    <strong>Add new player</strong>
                  </CButton>
                  <PlayerSearchForm
                    searchTerm={searchTerm} // Pass search term as prop
                    setSearchTerm={setSearchTerm} // Pass setter function
                    onPlayerSelect={handlePlayerSelect}
                    sites={sites}
                  />
                </CCol>
                <CCol lg={4}>
                  <CButton
                    color="info"
                    style={{ width: "100%", marginBottom: "-14px" }}
                    onClick={DelPlayerBtnOnClick}
                  >
                    <strong>Delete player</strong>
                  </CButton>
                  <PlayerDetailsForm
                    player={selectedPlayer}
                    searchTerm={searchTerm} // Pass search term as prop
                    setSearchTerm={setSearchTerm}
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {getAddNewPlayerForm()}
      <LoadingScreen
        loading={adding}
        bgColor="rgba(255,255,255,0.1)"
        spinnerColor="#000000"
      >
        <div></div>
      </LoadingScreen>
      <LoadingScreen
        loading={deleting}
        bgColor="rgba(255,255,255,0.1)"
        spinnerColor="#000000"
      >
        <div></div>
      </LoadingScreen>
    </div>
  );
};
export default React.memo(AddRemovePlayerPage);
