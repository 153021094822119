
const initialState = {
    data: null,
}
  
export const easySeatUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'set_easyseat_users':
        return {
            ...state,
            data : action.payload,
        };
    default:
        return state
    }
}
