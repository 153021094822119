import SndUnknown from "assets/sounds/Unknown.mp3";
import SndLightBuddy from "assets/sounds/LightBuddy.mp3";
import SndBuddy from "assets/sounds/Buddy.mp3";
import SndMegaBuddy from "assets/sounds/MegaBuddy.mp3";
import SndUltraBuddy from "assets/sounds/UltraBuddy.mp3";

import {
    UNKNOWN,
    LIGHTBUDDY,
    BUDDY,
    MEGABUDDY,
    ULTRABUDDY,
  } from "constant/constant";


export default function PlaySound(playertype, soundOnOff){

  if (!soundOnOff) return null;

  let audio = new Audio(SndUnknown);

  if(playertype === UNKNOWN){
    audio = new Audio(SndUnknown);
  }else if(playertype === LIGHTBUDDY){
    audio = new Audio(SndLightBuddy);
  }else if(playertype === BUDDY){
    audio = new Audio(SndBuddy);
  }else if(playertype === ULTRABUDDY){
    audio = new Audio(SndUltraBuddy);
  }else{
    audio = new Audio(SndMegaBuddy);
  }

  const promise = audio.play();
  if (promise !== undefined) {
    promise
      .then(() => {
        // Sound played successfully
      })
      .catch((error) => {
        // console.error("Error playing sound:", error);
      });
  }
  return null;
}
