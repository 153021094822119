import { CMultiSelect } from "@coreui/react-pro";
import { GetTableTypeObjectFromMulSel } from "helpers";
import React, { useRef } from "react";

export default function TableTypeMultiSelector({
  disableProfile,
  options,
  UpdateTableTypeProfileSetting,
}) {
  const multiSelectRef = useRef(null);

  const handleChange = (a) => {
    UpdateTableTypeProfileSetting(GetTableTypeObjectFromMulSel(a));
  };

  const handleClickOutside = (event) => {
    if(!event.target.classList.contains('form-multi-select-option-with-checkbox') && !event.target.classList.contains('form-multi-select-all')){
      if (!multiSelectRef.current.classList.contains('show')) {
        multiSelectRef.current.classList.add("show");
        multiSelectRef.current.setAttribute("aria-expanded", true);
      } else {
        multiSelectRef.current.classList.remove("show");
        multiSelectRef.current.setAttribute("aria-expanded", false);
      }
    }
  };

  return (
    <div>
      <div onClick={handleClickOutside} style={{ cursor: "pointer" }}>
        <CMultiSelect
          style={{ cursor: "pointer" }}
          ref={multiSelectRef}
          options={options}
          disabled={disableProfile}
          selectionType="tags"
          optionsStyle="checkbox"
          selectAll={true}
          selectAllLabel="Select all"
          placeholder="Please select the game types..."
          search={false}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
