import { createClient } from "@supabase/supabase-js";
import {
  SUPABASE_KEY,
  SUPABASE_URL,
  SUPA_SITES_UNIONS_CLUBS_TABLE,
  SUPA_USER_TABLE,
  SUPA_ACCESS_SITE_TABLE,
  SUPA_STACK_CONVERTOR_TABLE,
  SUPA_TELEGRAM_TABLE,
} from "constant/constant";

// Instance of client to connect the supabase backend
export const supaClient = createClient(SUPABASE_URL, SUPABASE_KEY);

/** EasySeat Users */

// Function to get users info from supbase backend's easyseat_user table
export async function GetAllUsers() {
  try {
    const { data, error } = await supaClient
      .from(SUPA_USER_TABLE)
      .select("*")
      .eq("type", "1");
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to get user info from supbase backend's easyseat_user table
export async function GetUser(username) {
  try {
    const { data, error } = await supaClient
      .from(SUPA_USER_TABLE)
      .select("*")
      .eq("username", username);
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to get user info from supbase backend's easyseat_user table
export async function GetUserByUserName(user) {
  try {
    const { data, error } = await supaClient
      .from(SUPA_USER_TABLE)
      .select("*")
      .eq("username", user.username);
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to insert users info to supbase backend's easyseat_user table
export async function InsertUser(data) {
  try {
    const { error } = await supaClient.from(SUPA_USER_TABLE).insert(data);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

// Function to update user info of supbase backend's easyseat_user table
export async function UpdateUser(data) {
  try {
    const { error } = await supaClient
      .from(SUPA_USER_TABLE)
      .update(data)
      .eq("id", data.id)
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

// Function to delete user info to supbase backend's easyseat_user table
export async function DeleteUser(id) {
  try {
    const { error } = await supaClient
      .from(SUPA_USER_TABLE)
      .delete()
      .eq("id", id);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// Function to update user info to supbase backend's easyseat_user table
export async function UpdateUserProfileName(user) {
  try {
    const { error } = await supaClient
      .from(SUPA_USER_TABLE)
      .update(user)
      .eq("id", user.id);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/** EasySeat sites_unions_clubs */

// Function to get sites_unions_clubs info from supbase backend's easyseat_sites_unions_clubs table
export async function GetSitesUnionsClubs() {
  try {
    const { data, error } = await supaClient
      .from(SUPA_SITES_UNIONS_CLUBS_TABLE)
      .select("*");
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to insert sites_unions_clubs info to supbase backend's easyseat_sites_unions_clubs table
export async function InsertSitesUnionsClubs(data) {
  try {
    const { error } = await supaClient
      .from(SUPA_SITES_UNIONS_CLUBS_TABLE)
      .insert(data);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

// Function to update sites_unions_clubs info of supbase backend's easyseat_sites_unions_clubs table
export async function UpdateSitesUnionsClubs(data) {
  try {
    const { error } = await supaClient
      .from(SUPA_SITES_UNIONS_CLUBS_TABLE)
      .update(data)
      .eq("id", data.id);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// Function to delete user info to supbase backend's easyseat_user table
export async function DeleteSitesUnionsClubs(id) {
  try {
    const { error } = await supaClient
      .from(SUPA_SITES_UNIONS_CLUBS_TABLE)
      .delete()
      .eq("id", id);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/** EasySeat access_site */

// Function to get access_site info from supbase backend's access_site table by user id
export async function GetUserAccess(user_nid) {
  try {
    const { data, error } = await supaClient
      .from(SUPA_ACCESS_SITE_TABLE)
      .select("*")
      .eq("user_nid", user_nid);
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to insert access_site info to supbase backend's access_site table
export async function InsertUserAccess(data) {
  try {
    const { error } = await supaClient
      .from(SUPA_ACCESS_SITE_TABLE)
      .insert(data);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return true;
  }
}

// Function to update access_site info of supbase backend's access_site table
export async function UpdateUserAccess(data) {
  try {
    const { error } = await supaClient
      .from(SUPA_ACCESS_SITE_TABLE)
      .update(data)
      .eq("user_nid", data.user_nid);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// Function to delete access_site info to supbase backend's access_site table
export async function DeleteUserAccess(user_nid) {
  try {
    const { error } = await supaClient
      .from(SUPA_ACCESS_SITE_TABLE)
      .delete()
      .eq("user_nid", user_nid);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/** EasySeat stack_convertor */

// Function to get stack_convertor info from supbase backend's stack_convertor table by user id
export async function GetStackConvertor(id) {
  try {
    const { data, error } = await supaClient
      .from(SUPA_STACK_CONVERTOR_TABLE)
      .select("*")
      .eq("id", id);
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to insert stack_convertor info to supbase backend's stack_convertor table
export async function InsertStackConvertor(data) {
  try {
    const { error } = await supaClient
      .from(SUPA_STACK_CONVERTOR_TABLE)
      .insert(data);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return true;
  }
}

// Function to update stack_convertor info of supbase backend's stack_convertor table
export async function UpdateStackConvertor(data) {
  try {
    const { error } = await supaClient
      .from(SUPA_STACK_CONVERTOR_TABLE)
      .update(data)
      .eq("id", data.id);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// Function to delete stack_convertor info to supbase backend's stack_convertor table
export async function DeleteStackConvertor(id) {
  try {
    const { error } = await supaClient
      .from(SUPA_STACK_CONVERTOR_TABLE)
      .delete()
      .eq("id", id);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// Telegram Setting
// Function to telegram setting info from supbase backend's easyseat_telegram table
export async function GetTelegramSettingByUserId(user_id) {
  try {
    const { data, error } = await supaClient
      .from(SUPA_TELEGRAM_TABLE)
      .select("*")
      .eq("user_id", user_id);
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to telegram setting info from supbase backend's easyseat_telegram table
export async function GetTelegramAdminSetting() {
  try {
    const { data, error } = await supaClient
      .from(SUPA_TELEGRAM_TABLE)
      .select("*")
      .eq("telegram_admin", '0');
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to insert telegram setting to supbase backend's easyseat_telegram table
export async function InsertTelegramSetting(data) {
  try {
    const { error } = await supaClient.from(SUPA_TELEGRAM_TABLE).insert(data);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}


// Function to update telegram setting of supbase backend's easyseat_telegram table
export async function UpdateTelegramSetting(data) {
  try {
    const { error } = await supaClient
      .from(SUPA_TELEGRAM_TABLE)
      .update(data)
      .eq("id", data.id)
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

// Function to delete telegram setting info to supbase backend's easyseat_telegram table
export async function DeleteTelegramSetting(user_id) {
  try {
    const { error } = await supaClient
      .from(SUPA_TELEGRAM_TABLE)
      .delete()
      .eq("user_id", id);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export function getRTSupaClient() {
  try {
    const supaRT = createClient(SUPABASE_URL, SUPABASE_KEY);
    return supaRT;
  } catch (error) {
    return null;
  }
}
