import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CButtonGroup,
  CContainer,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CFormSelect,
  CNavItem,
  CButton,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import { LOGOUT } from "../constant/constant";
import { GetProfileNames } from "helpers/convert";
import titleImg from "../assets/images/backimgs/title.png";
import FontAwesome from "react-fontawesome";
import {UpdateUserProfileName} from "../helpers/admin/supabase"
import { AppHeaderDropdown } from "./header/index";

const AppHeader = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.user[0]);
  const profiles = useSelector((x) => x.profileState.profiles);
  const curProfileIndex = useSelector(
    (x) => x.profileState.currentProfileIndex
  );
  const showProfile = useSelector((x) => x.changeState.showProfile);
  const theme = useSelector((state) => state.changeState.theme);

  theme === "dark"
    ? document.body.classList.add("dark-theme")
    : document.body.classList.remove("dark-theme");

  const sidebarShow = useSelector((state) => state.changeState.sidebarShow);
  const soundOnOff = useSelector((state) => state.changeState.soundOnOff);
  const telegramOnOff = useSelector((state) => state.changeState.telegramOnOff);

  const GetThemeIcon = (theme) => {
    if (theme === "dark") {
      return <FontAwesome name="moon" size="lg"></FontAwesome>;
    } else {
      return <FontAwesome name="sun" size="lg"></FontAwesome>;
    }
  };

  const GetSoundIcon = (soundOnOff) => {
    if (soundOnOff) {
      return <FontAwesome name="volume-high" size="lg"></FontAwesome>;
    } else {
      return <FontAwesome name="volume-xmark" size="lg"></FontAwesome>;
    }
  };

  const GetTelegramIcon = (telegramOnOff) => {
    if (!telegramOnOff) {
      return <FontAwesome name="bell" size="lg"></FontAwesome>;
    } else {
      return <FontAwesome name="bell-slash" size="lg"></FontAwesome>;
    }
  };

  const getDOMElementProfileSelectForm = () => {
    return (
      <CHeaderNav
        className="d-md-flex me-auto px-1"
        style={{ visibility: !showProfile ? "hidden" : "visible" }}
      >
        <CNavItem className="d-flex">
          <CFormSelect
            options={GetProfileNames(profiles)}
            value={curProfileIndex}
            onChange={SelectProfileOnChange}
          ></CFormSelect>
        </CNavItem>
      </CHeaderNav>
    );
  };

  const getDOMElementThemeSoundTelegramButtonGroup = () => {
    return (
      <CHeaderNav>
        <CButtonGroup aria-label="Theme switch">
          {/* <CButton
            color="danger"
            style={{ width: "46px" }}
            onClick={() => {
              if (theme === "dark") {
                dispatch({ type: "set", theme: "default" });
              } else {
                dispatch({ type: "set", theme: "dark" });
              }
            }}
          >
            {GetThemeIcon(theme)}
          </CButton> */}
          <CButton
            style={{ width: "48px" }}
            color="warning"
            onClick={() => dispatch({ type: "set", soundOnOff: !soundOnOff })}
          >
            {GetSoundIcon(soundOnOff)}
          </CButton>
          {/* <CButton
            color="info"
            style={{ width: "46px" }}
            className="ms-auto"
            onClick={() =>
              dispatch({ type: "set", telegramOnOff: !telegramOnOff })
            }
          >
            {GetTelegramIcon(telegramOnOff)}
          </CButton> */}
        </CButtonGroup>
      </CHeaderNav>
    );
  };
  const onLogoutHandler = () => {
    localStorage.clear();
    dispatch({ type: LOGOUT, payload: null });
  };

  const SelectProfileOnChange = (a) => {
    user.profile_name = profiles[a.target.value]["name"];
    UpdateUserProfileName(user).then((val) => {});
    dispatch({ type: "set_current_profile", payload: a.target.value });
  };

  const getHeaderToggler = () => {
    return (
      <CHeaderToggler
        className="ps-1 easyseat-header-menu"
        onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
      >
        <CIcon icon={cilMenu} size="lg" />
        <div className="easyseat-header-title" style={{ display: "inline" }}>
          Menu
        </div>
      </CHeaderToggler>
    );
  };

  const getLogoEle = () => {
    return (
      <div
        className="easyseat-header-title"
        style={{
          marginRight: "26%",
          position: "absolute",
          left: "36%",
          paddingTop: "10px",
        }}
      >
        <FontAwesome
          name="dragon"
          size="2x"
          border={false}
          style={{ color: "white" }}
        ></FontAwesome>
        <img
          src={titleImg}
          alt="site"
          width="260px"
          height="50px"
          style={{ margin: "-4px 8px 0px 8px" }}
        ></img>
      </div>
    );
  };

  return (
    <CHeader position="sticky" className="mb-1">
      <CContainer fluid>
        {getHeaderToggler()}
        {getDOMElementProfileSelectForm()}
        {getLogoEle()}
        {getDOMElementThemeSoundTelegramButtonGroup()}
        {/* <CHeaderNav className="ms-4 me-2 easyseat-header-user">
          <AppHeaderDropdown onLogoutAction={onLogoutHandler} />
        </CHeaderNav> */}
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
