import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCard,
  CCardHeader,
  CRow,
  CCol,
  CCardBody,
  CFormLabel,
  CButton,
} from "@coreui/react-pro";
import { SketchPicker } from "react-color";
import {
  GetPlayerColors,
  InsertPlayerColors,
  UpdatePlayerColors,
} from "../../helpers/supabase";
import { history } from "helpers";
import FontAwesome from "react-fontawesome";
import {
  BUDDY,
  LIGHTBUDDY,
  MEGABUDDY,
  ULTRABUDDY,
  UNKNOWN,
} from "constant/constant";

import useSound from "use-sound";
import SndUnknown from "../../assets/sounds/Unknown.mp3";
import SndLightBuddy from "../../assets/sounds/LightBuddy.mp3";
import SndBuddy from "../../assets/sounds/Buddy.mp3";
import SndMegaBuddy from "../../assets/sounds/MegaBuddy.mp3";
import SndUltraBuddy from "../../assets/sounds/UltraBuddy.mp3";

const PlayerColorsPage = () => {
  const dispatch = useDispatch();
  const user_nid = useSelector((state) => state.login.user[0].id);
  const pColors = useSelector((state) => state.playerColorState.playerColors);

  const soundOnOff = useSelector((state) => state.changeState.soundOnOff);

  const [SndUnknownIns] = useSound(SndUnknown, { interrupt: true });
  const [SndLightBuddyIns] = useSound(SndLightBuddy, { interrupt: true });
  const [SndBuddyIns] = useSound(SndBuddy, { interrupt: true });
  const [SndMegaBuddyIns] = useSound(SndMegaBuddy, { interrupt: true });
  const [SndUltraBuddyIns] = useSound(SndUltraBuddy, { interrupt: true });

  const playSoundByPlayerType = (playertype, IsSoundEnable) => {
    try {
      if (!IsSoundEnable) return;
      switch (playertype) {
        case LIGHTBUDDY:
          SndLightBuddyIns();
          break;
        case BUDDY:
          SndBuddyIns();
          break;
        case MEGABUDDY:
          SndMegaBuddyIns();
          break;
        case ULTRABUDDY:
          SndUltraBuddyIns();
          break;
        default:
          SndUnknownIns();
          break;
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    if (history.location.pathname.includes("dashboard")) {
      dispatch({ type: "set", showProfile: true });
    } else {
      dispatch({ type: "set", showProfile: false });
    }
  }, [dispatch]);

  const [fillColor, SetFillColor] = useState(null);
  const [selectType, SetSelecttype] = useState(null);

  const changeFillColor = (color) => {
    const fcolor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    SetFillColor(fcolor);
  };

  const pickerColorOnclick = (type) => {
    SetFillColor(pColors[type]);
    if (selectType) {
      SetSelecttype(null);
    } else {
      SetSelecttype(type);
    }
  };
  const setButtonOnClick = (e) => {
    SetSelecttype(null);
    pColors[selectType] = fillColor;
    SetFillColor(JSON.parse(JSON.stringify(pColors)));
    GetPlayerColors(user_nid)
      .then((data) => {
        if (data.length === 1) {
          UpdatePlayerColors(user_nid, pColors).then((ret) => {
            dispatch({ type: "set_player_color", payload: pColors });
          });
        } else {
          pColors["user_nid"] = user_nid;
          InsertPlayerColors(pColors).then((ret) => {
            dispatch({ type: "set_player_color", payload: pColors });
          });
        }
      })
      .catch((error) => {});
  };

  const GetColorPickerDialog = () => {
    if (selectType) {
      return (
        <div style={{ marginLeft: "20%", marginTop: "10%" }}>
          <SketchPicker color={fillColor} onChange={changeFillColor} />
          <CButton color="info" className="mt-2" onClick={setButtonOnClick}>
            Set Color
          </CButton>
        </div>
      );
    }
  };

  const getDOMElement = () => {
    return (
      <CCard className="mb-2 mt-2">
        <CCardHeader>
          <strong>Set A Color For Each Player</strong>
        </CCardHeader>
        <CCardBody className="easyseat-text-align-left">
          <CRow>
            <CFormLabel
              style={{
                width: "40%",
                display: "inline-block",
                marginLeft: "20%",
              }}
            >
              <strong>Unknown : </strong>
            </CFormLabel>
            <CButton
              style={{
                backgroundColor: pColors[UNKNOWN],
                width: "25px",
                height: "25px",
                display: "inline-block",
              }}
              onClick={() => {
                pickerColorOnclick(UNKNOWN);
              }}
            ></CButton>
            <CButton
              style={{ width: "35px", height: "25px", marginLeft: "10px" }}
              color="info"
              onClick={() => playSoundByPlayerType(UNKNOWN, true)}
            >
              <FontAwesome
                className="easyseat-p-color-setting-snd-btn"
                name="volume-high"
              ></FontAwesome>
            </CButton>
          </CRow>
          <CRow>
            <CFormLabel
              style={{
                width: "40%",
                display: "inline-block",
                marginLeft: "20%",
              }}
            >
              <strong>Light Buddy : </strong>
            </CFormLabel>
            <CButton
              style={{
                backgroundColor: pColors[LIGHTBUDDY],
                width: "25px",
                height: "25px",
                display: "inline-block",
              }}
              onClick={() => {
                pickerColorOnclick(LIGHTBUDDY);
              }}
            ></CButton>
            <CButton
              style={{ width: "35px", height: "25px", marginLeft: "10px" }}
              color="info"
              onClick={() => playSoundByPlayerType(LIGHTBUDDY, true)}
            >
              <FontAwesome
                className="easyseat-p-color-setting-snd-btn"
                name="volume-high"
              ></FontAwesome>
            </CButton>
          </CRow>
          <CRow>
            <CFormLabel
              style={{
                width: "40%",
                display: "inline-block",
                marginLeft: "20%",
              }}
            >
              <strong>Buddy : </strong>
            </CFormLabel>
            <CButton
              style={{
                backgroundColor: pColors[BUDDY],
                width: "25px",
                height: "25px",
                display: "inline-block",
              }}
              onClick={() => {
                pickerColorOnclick(BUDDY);
              }}
            ></CButton>
            <CButton
              style={{ width: "35px", height: "25px", marginLeft: "10px" }}
              color="info"
              onClick={() => playSoundByPlayerType(BUDDY, true)}
            >
              <FontAwesome
                className="easyseat-p-color-setting-snd-btn"
                name="volume-high"
              ></FontAwesome>
            </CButton>
          </CRow>
          <CRow>
            <CFormLabel
              style={{
                width: "40%",
                display: "inline-block",
                marginLeft: "20%",
              }}
            >
              <strong>Mega Buddy : </strong>
            </CFormLabel>
            <CButton
              style={{
                backgroundColor: pColors[MEGABUDDY],
                width: "25px",
                height: "25px",
                display: "inline-block",
              }}
              onClick={() => {
                pickerColorOnclick(MEGABUDDY);
              }}
            ></CButton>
            <CButton
              style={{ width: "35px", height: "25px", marginLeft: "10px" }}
              color="info"
              onClick={() => playSoundByPlayerType(MEGABUDDY, true)}
            >
              <FontAwesome
                className="easyseat-p-color-setting-snd-btn"
                name="volume-high"
              ></FontAwesome>
            </CButton>
          </CRow>
          <CRow>
            <CFormLabel
              style={{
                width: "40%",
                display: "inline-block",
                marginLeft: "20%",
              }}
            >
              <strong>Ultra Buddy : </strong>
            </CFormLabel>
            <CButton
              style={{
                backgroundColor: pColors[ULTRABUDDY],
                width: "25px",
                height: "25px",
                display: "inline-block",
              }}
              onClick={() => {
                pickerColorOnclick(ULTRABUDDY);
              }}
            ></CButton>
            <CButton
              style={{ width: "35px", height: "25px", marginLeft: "10px" }}
              color="info"
              onClick={() => playSoundByPlayerType(ULTRABUDDY, true)}
            >
              <FontAwesome
                className="easyseat-p-color-setting-snd-btn"
                name="volume-high"
              ></FontAwesome>
            </CButton>
          </CRow>
          {GetColorPickerDialog()}
        </CCardBody>
      </CCard>
    );
  };

  return (
    <div className="align-items-center" style={{ maxWidth: "800px" }}>
      <CRow>
        <CCol lg={3}></CCol>
        <CCol lg={6}>{getDOMElement()}</CCol>
        <CCol lg={3}></CCol>
      </CRow>
    </div>
  );
};
export default React.memo(PlayerColorsPage);
