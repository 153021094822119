import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CButton,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { AppSidebarNav } from "./AppSidebarNav";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { cibCashapp, cilAccountLogout, cilMenu } from "@coreui/icons";
import userNavi from "./navigations/UserNav";
import adminNavi from "./navigations/AdminNav";
import { LOGOUT } from "constant/constant";
import {
  cilCalculator,
  cilApple,
  cilUserPlus,
  cilBeachAccess,
  cilStar,
  cilSettings,
  cilColorPalette,
  cilSchool,
} from "@coreui/icons";

const AppSidebar = () => {
  const users = useSelector((state) => state.login.user);

  const dispatch = useDispatch();

  const unfoldable = useSelector(
    (state) => state.changeState.sidebarUnfoldable
  );
  const sidebarShow = useSelector((state) => state.changeState.sidebarShow);

  const GetNavigation = () => {
    if (users && users[0].type == 1) {
      return userNavi;
    } else {
      return adminNavi;
    }
  };

  const onLogoutHandler = () => {
    localStorage.clear();
    dispatch({ type: LOGOUT, payload: null });
  };

  const GetSidebarTogglerEle = () => {
    return (
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() =>
          dispatch({ type: "set", sidebarUnfoldable: !unfoldable })
        }
      />
    );
  };

  const GetLogoutEle = () => {
    if (!unfoldable) {
      return (
        <div className="easyseat-sidebar-logout-div">
          <div
            style={{
              marginLeft: "15%",
              width: "70%",
              marginTop: "2px",
              textAlign: "center",
              color: "#ffffffa1",
            }}
          >
            User: {users[0].username}
          </div>
          <CButton
            color="dark"
            style={{
              marginLeft: "15%",
              width: "70%",
              marginTop: "4px",
              marginBottom: "4px",
            }}
            onClick={onLogoutHandler}
          >
            {" "}
            <CIcon
              icon={cilAccountLogout}
              style={{ marginRight: "10px" }}
              customClassName="nav-icon"
            />
            Logout
          </CButton>
        </div>
      );
    } else {
      return (
        <CButton
          color="dark"
          style={{
            marginLeft: "20%",
            width: "60%",
            marginTop: "50px",
            marginBottom: "4px",
          }}
          onClick={onLogoutHandler}
        >
          <CIcon
            icon={cilAccountLogout}
            customClassName="nav-icon"
            style={{ marginLeft: "-6px", marginTop: "-4px" }}
          />
        </CButton>
      );
    }
  };

  const GetBrandEle = () => {
    return (
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <div className="sidebar-brand-full">
          <CIcon icon={cilMenu} />
          <h4 style={{ display: "inline", marginLeft: 12 }}>menu</h4>
        </div>
        <CIcon className="sidebar-brand-narrow" icon={cibCashapp} height={35} />
      </CSidebarBrand>
    );
  };

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
    >
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={GetNavigation()} />
        </SimpleBar>
        {GetLogoutEle()}
      </CSidebarNav>
      {GetSidebarTogglerEle()}
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
