import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import titleImg from "../assets/images/backimgs/title.png";
import { history } from "../helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { loginFailToast, loginSuccessToast } from "../helpers/toast-alert";
import { ClearEasySeatDataOnSupabase, GetLoginUserInfor } from "../helpers/supabase";
import FontAwesome from "react-fontawesome";

const Login = () => {
  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.login.user);
  const [logining, SetLogining] = useState(false);

  useEffect(() => {
    if (loginUser) {
      history.navigate("/home/dashboard");
    }
  }, [loginUser]);

  // form validation rules
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = ({ username, password }) => {
    SetLogining(true);
    GetLoginUserInfor(username, password)
      .then((data) => {
        if (data && data.length > 0) {
          localStorage.setItem("user", JSON.stringify(data));
          loginSuccessToast();
          dispatch({ type: "LOGIN", payload: data });
          ClearEasySeatDataOnSupabase().then(val=>{
            dispatch({ type: "LOGIN", payload: data });
          })
        } else if (data && data.length == 0) {
          loginFailToast();
          SetLogining(false);
        } else {
          SetLogining(false);
          alert(
            "Backend's API is not working now. It seems has some issues. Please check."
          );
        }
      })
      .catch((error) => {
        loginFailToast();
        SetLogining(false);
      });
  };

  const GetTitleInLoginForm = () => {
    return (
      <div style={{ display: "inline" }}>
        <FontAwesome
          name="dragon"
          size="2x"
          border={false}
          style={{ color: "white" }}
        ></FontAwesome>
        <img
          src={titleImg}
          alt="site"
          width="240px"
          height="50px"
          style={{ margin: "-4px 0px 0px 4px" }}
        ></img>
      </div>
    );
  };

  const GetUserInputForm = () => {
    return (
      <CInputGroup className="mb-3">
        <CInputGroupText>
          <CIcon icon={cilUser} />
        </CInputGroupText>
        <CFormInput
          placeholder="Username"
          autoComplete="username"
          {...register("username")}
          className={`form-control ${errors.username ? "is-invalid" : ""}`}
        />
      </CInputGroup>
    );
  };
  const GetPasswordInputForm = () => {
    return (
      <CInputGroup className="mb-4">
        <CInputGroupText>
          <CIcon icon={cilLockLocked} />
        </CInputGroupText>
        <CFormInput
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          {...register("password")}
          className={`form-control ${errors.password ? "is-invalid" : ""}`}
        />
      </CInputGroup>
    );
  };
  const GetLoginFormCard = () => {
    return (
      <CCard className="p-4" style={{ background: "#0000003d" }}>
        <CCardBody>
          <CForm>
            {/* <p className="text-medium-emphasis">
              {GetTimeCreatedAt("2024-02-01T08:50:21.445073+00:00")}
            </p> */}
            {GetTitleInLoginForm()}
            <p className="text-medium-emphasis">Sign In to your account</p>
            {GetUserInputForm()}
            {GetPasswordInputForm()}
            <CRow>
              <CCol xs={8}>
                <CLoadingButton
                  loading={logining}
                  color="dark"
                  className="px-4 mx-4"
                  style={{ width: "240px" }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Login
                </CLoadingButton>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    );
  };
  return (
    <div className="easyseat-login-page min-vh-100 d-flex flex-row align-items-center dark-theme">
      <CContainer style={{ maxWidth: "900px" }}>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>{GetLoginFormCard()}</CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};
export default React.memo(Login);
