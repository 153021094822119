import siteClubGGLogo from "../assets/logos/clubgg.png";
import siteWinaLogo from "../assets/logos/winamax.png";
import siteAmericasLogo from "../assets/logos/americas.png";
import siteGgpbLogo from "../assets/logos/ggpokerblack.png";
import site888PokerLogo from "../assets/logos/888poker.png";
import sitePkstarLogo from "../assets/logos/pokerstarslogo.png";
import sitePPPLogo from "../assets/logos/ppp_logo.png";
import {
  PPP,
  WINAMAX,
  CLUBGG,
  POKERSTARS,
  S888POKER,
  AMERICASCARDROOM,
} from "constant/constant";
import { CFormLabel } from "@coreui/react-pro";

export const GetLogoImage = (site) => {
  switch (site) {
    case PPP:
      return sitePPPLogo;
    case WINAMAX:
      return siteWinaLogo;
    case CLUBGG:
      return siteClubGGLogo;
    case POKERSTARS:
      return sitePkstarLogo;
    case S888POKER:
      return site888PokerLogo;
    case AMERICASCARDROOM:
      return siteAmericasLogo;
    default:
      return siteGgpbLogo;
  }
};

export const GetPlayerTypeBlock = (key, type, pColors) => {
  if (type === "regular")
    return (
      <CFormLabel
        key={key}
        style={{
          width: "18px",
          height: "18px",
          backgroundColor: "#ffffff00",
          margin: "0px 8px -2px 2px",
        }}
      ></CFormLabel>
    );
  return (
    <CFormLabel
      key={key}
      style={{
        width: "18px",
        height: "18px",
        backgroundColor: pColors[type],
        margin: "0px 8px -2px 2px",
      }}
    ></CFormLabel>
  );
};

export const GetLogoImgElement = (site) => {
  return (
    <img
      src={GetLogoImage(site)}
      style={{ width: "18px", height: "18px", marginRight: "8px" }}
      alt={site}
    />
  );
};

export const ConvertLowercase = (input) => {
  const result = input.replace(/[a-zA-Z]/g, (char) => char.toLowerCase());
  return result;
};

export const CheckIfInteger = (value) => {
  const parsedValue = parseInt(value, 10);
  return !isNaN(parsedValue) && parsedValue.toString() === value;
}

