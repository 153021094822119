import React, { useState } from "react";
import { CRow, CCol, CFormLabel } from "@coreui/react-pro";
import TableTypeDBLogo from "../../assets/tabletype/DB.png";
import TableTypeCallTimeLogo from "../../assets/tabletype/Calltime.png";
import TableTypeVPIPLogo from "../../assets/tabletype/vpip.png";
import TableTypeBPLogo from "../../assets/tabletype/BP.png";
import PlayerLogo from "../../assets/tabletype/player.png";

import siteClubGGLogo from "../../assets/logos/clubgg.png";
import siteWinaLogo from "../../assets/logos/winamax.png";
import siteAmericasLogo from "../../assets/logos/americas.png";
// import siteGgpLogo from "../../assets/logos/ggpoker.png";
import siteGgpbLogo from "../../assets/logos/ggpokerblack.png";
import site888PokerLogo from "../../assets/logos/888poker.png";
import sitePkstarLogo from "../../assets/logos/pokerstarslogo.png";
import sitePPPLogo from "../../assets/logos/ppp_logo.png";
import { GetRealStakeWithDollar } from "../../helpers/trasform";

import {
  TT_CallTIME,
  TT_DB,
  TT_VPIP,
  PPP,
  WINAMAX,
  CLUBGG,
  POKERSTARS,
  S888POKER,
  TT_BP,
  DIMENTION_IPAD,
  AMERICASCARDROOM,
  UNKNOWN,
  LIGHTBUDDY,
  BUDDY,
  MEGABUDDY,
  ULTRABUDDY,
} from "constant/constant";
import { IsInteger } from "helpers";
import { RemoveSpaceInText } from "helpers";

const EasySeatItemRowWidgetNew = ({ a, pt, minPlayerType, pColors, siteUnionClubs, soundOnOff }) => {

  // console.log(a);
  // console.log(minPlayerType);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  let wdthClub = "150px";
  let wdthGameType = "130px";
  let wdthTableName = "200px";
  let wdthBlinds = "130px";
  let plyCount = a["nids"].length;

  if (dimensions.width < DIMENTION_IPAD) {
    wdthClub = "130px";
    wdthGameType = "110px";
    wdthTableName = "140px";
    wdthBlinds = "130px";
    plyCount = 0;
  }

  const getEnableMinPlayerTypes = () =>{
    let ret = [];
    if(minPlayerType[UNKNOWN] > 1){
      ret.push(UNKNOWN);
    }
    if(minPlayerType[LIGHTBUDDY] > 1){
      ret.push(LIGHTBUDDY);
    }
    if(minPlayerType[BUDDY] > 1){
      ret.push(BUDDY);
    }
    if(minPlayerType[MEGABUDDY] > 1){
      ret.push(MEGABUDDY);
    }
    if(minPlayerType[ULTRABUDDY] > 1){
      ret.push(ULTRABUDDY);
    }
    return ret;
  }

  let enableMinPlayerTypes = getEnableMinPlayerTypes();

  const GetCurrency = () => {
    let ret = "$";
    
    siteUnionClubs.forEach((element) => {
      if (element.site_name === a.site) {
        ret = element.site_currency;
      }
    });
    
    return ret;
  };

  const getShowAbleNids = () =>{
    let ret = [];
    let unret = [];

    a["nids"].forEach(ele=>{
      if(enableMinPlayerTypes.includes(a['playertypes'][ele])){
        if(!(a['playertypes'][ele] === "regular" || !pt[a['playertypes'][ele]])){
          ret.push(ele);
        }
      }else{
        if(!(a['playertypes'][ele] === "regular" || !pt[a['playertypes'][ele]])){
          unret.push(ele);
        }
      }
    });

    const playersCount = Math.min(a["nids"].length, 3);
    ret = ret.slice(0, playersCount);
    unret.forEach(ele => {
      if(ret.length <3 ){
        ret.push(ele);
      }
    });
    // console.log(ret);
    return ret;
  }

  let ptBlocks = getShowAbleNids();
  let ind = 0;
  let key = 0;
  const GetLogoImage = (site) => {
    if (site === PPP) {
      return sitePPPLogo;
    } else if (site === WINAMAX) {
      return siteWinaLogo;
    } else if (site === CLUBGG) {
      return siteClubGGLogo;
    } else if (site === POKERSTARS) {
      return sitePkstarLogo;
    } else if (site === S888POKER) {
      return site888PokerLogo;
    } else if (site === AMERICASCARDROOM) {
      return siteAmericasLogo;
    } else {
      return siteGgpbLogo;
    }
  };

  const GetPlayerTypeBlock = (key, type) => {
    if (type === "regular") return;
    if (!pt[type]) return;
    return (
      <CFormLabel
        key={key}
        style={{
          width: "16px",
          height: "16px",
          backgroundColor: pColors[type],
          margin: "0px 1px -2px 1px",
          borderRadius:"4px",
        }}
      ></CFormLabel>
    );
  };

  const GetTableTypeLogoImage = (tabletype) => {
    if (RemoveSpaceInText(tabletype) === RemoveSpaceInText(TT_VPIP)) {
      return TableTypeVPIPLogo;
    } else if (RemoveSpaceInText(tabletype) === RemoveSpaceInText(TT_BP)) {
      return TableTypeBPLogo;
    } else if (RemoveSpaceInText(tabletype) === RemoveSpaceInText(TT_DB)) {
      return TableTypeDBLogo;
    } else if (
      RemoveSpaceInText(tabletype) === RemoveSpaceInText(TT_CallTIME)
    ) {
      return TableTypeCallTimeLogo;
    } else {
      return null;
    }
  };

  const GetFormLabel = (text) => {
    return (
      <CFormLabel style={{ margin: "0px 4px 0px 4px", fontSize: 14 }}>
        <strong>{GetValidTableName(text)}</strong>
      </CFormLabel>
    );
  };

  const GetFormGameTypeLabel = (text) => {
    return (
      <CFormLabel style={{ margin: "0px 4px 0px 4px", fontSize: 14 }}>
        <strong>{text}</strong>
      </CFormLabel>
    );
  };

  const GetFormClubLabel = (clubname) => {
    return (
      <CFormLabel style={{ margin: "0px 4px 0px 4px", fontSize: 14 }}>
        <strong>{GetValidClubName(clubname)}</strong>
      </CFormLabel>
    );
  };

  const GetValidClubName = (clubname) => {
    let length = 20;
    if (dimensions.width < DIMENTION_IPAD) {
      length = 13;
    }
    if (!clubname) return null;
    if (clubname.length > length) {
      return clubname.substring(0, length) + "..";
    } else {
      return clubname;
    }
  };

  const GetValidNickName = (nickname) => {
    let length = 9;
    if (dimensions.width < DIMENTION_IPAD) {
      length = 7;
    }
    if (nickname.length > length) {
      return nickname.substring(0, length) + ".";
    } else {
      return nickname;
    }
  };

  const GetTableTypeLogo = (key, tabletype) => {
    if (tabletype === null) return null;
    return (
      <img
        key={key}
        src={GetTableTypeLogoImage(tabletype)}
        alt="site"
        width="16px"
        height="16px"
        style={{ margin: "-4px 0px 0px 0px" }}
      />
    );
  };

  const GetPlayerImgElement = () => {
    return (
      <img
        src={PlayerLogo}
        width="18px"
        height="18px"
        style={{ margin: "-4px 2px 0px 2px" }}
      />
    );
  };

  const GetValidTableName = (tablename) => {
    let length = 20;
    if (dimensions.width < DIMENTION_IPAD) {
      length = 14;
    }
    if (tablename.length > length) {
      return tablename.substring(0, length) + "..";
    } else {
      return tablename;
    }
  };

  const GetDateTimeElement = (time) => {
    return (
      <CFormLabel
        style={{
          margin: "0px 8px 0px 8px",
          borderBottom: "solid #897853 1px",
          fontSize: 14,
        }}
      >
        <strong>{time}</strong>
      </CFormLabel>
    );
  };

  const GetLogoImgElement = (site) => {
    return (
      <img
        src={GetLogoImage(site)}
        alt="site"
        width="16px"
        height="16px"
        style={{ margin: "-4px 8px 0px -4px" }}
      />
    );
  };
  
  return (
    <CRow className="easyseat-detail-item-row">
      <CCol
        lg={3}
        className="easyseat-detail-item-col"
        style={{ minWidth: "150px" }}
      >
        {GetDateTimeElement(a["created_at"])}
        {GetLogoImgElement(a["site"])}
        {ptBlocks.map((r) => {
          key = key + 1;
          return GetPlayerTypeBlock(key, a["playertypes"][r]);
        })}
      </CCol>
      {/* {PlaySound(a["playertypes"][a["nid"]], soundOnOff)} */}
      {/* {PlaySoundUse(a["playertypes"][a["nid"]], soundOnOff)} */}
      <CCol
        lg={1}
        className="easyseat-detail-item-col"
        style={{ minWidth: wdthBlinds }}
      >
        {a.realstack === ""
          ? GetFormGameTypeLabel(`${GetCurrency()}${a["blinds"]}`)
          : GetFormGameTypeLabel(
              `${GetRealStakeWithDollar(a.realstack, GetCurrency())}(${
                a["blinds"]
              })`
            )}
      </CCol>
      <CCol
        lg={1}
        className="easyseat-detail-item-col"
        style={{ minWidth: wdthGameType }}
      >
        {GetFormLabel(a["gametype"])}
        {a["tabletype"].map((r) => {
          key = key + 1;
          let typeLogo = GetTableTypeLogo(key, r);
          return typeLogo;
        })}
      </CCol>
      <CCol
        lg={1}
        className="easyseat-detail-item-col"
        style={{ minWidth: wdthClub }}
      >
        {GetFormClubLabel(a["club"])}
      </CCol>
      <CCol
        lg={1}
        className="easyseat-detail-item-col"
        style={{ minWidth: wdthTableName }}
      >
        {GetFormLabel(a["tablename"])}
      </CCol>
      <CCol lg={4} className="easyseat-detail-item-col">
        {GetPlayerImgElement()}
        <CFormLabel
          style={{
            margin: "0px 4px 0px 4px",
          }}
        >
          <strong>{`(${a["positions"].length}/${a["maxtablesize"]})`}</strong>
        </CFormLabel>
        {ptBlocks.map((r) => {
          let pt1 = a["playertypes"][r];
          if (IsInteger(r)) {
            pt1 = a["playertypes"][parseInt(r)];
          }
          if (true) {
            var nickname = a["nicknames"][a["nids"].indexOf(r)];
            if (ind === ptBlocks.length - 1) {
              key = key + 1;
              return (
                <div style={{ display: "contents" }} key={key}>
                  <CFormLabel>
                    <strong>{GetValidNickName(nickname)}</strong>
                  </CFormLabel>
                </div>
              );
            } else {
              key = key + 1;
              ind = ind + 1;
              return (
                <div style={{ display: "contents" }} key={key}>
                  <CFormLabel>
                    <strong>{GetValidNickName(nickname)},</strong>
                  </CFormLabel>
                </div>
              );
            }
          } else {
            key = key + 1;
          }
        })}
      </CCol>
    </CRow>
  );
};
export default React.memo(EasySeatItemRowWidgetNew);
