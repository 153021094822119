import React, { useState, useEffect, useRef } from "react";
import { CFormLabel } from "@coreui/react-pro";
import { useSelector } from "react-redux";
import { GetPlayerTypeBlock } from "helpers/utils";

const PlayerTypeFormSelect = ({ options, value, onChange }) => {
  const pColors = useSelector((state) => state.playerColorState.playerColors);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || { value: '', label: '' });
  const dropdownRef = useRef(null); // Create a ref for the dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onChange(option.value);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update selectedOption when value prop changes
  useEffect(() => {
    setSelectedOption(options.find(option => option.value === value) || { value: '', label: '' });
  }, [value, options]);

  return (
    <div style={{ position: "relative", width: "100%" }} ref={dropdownRef}>
      <div
        className="form-select"
        onClick={toggleDropdown}
        style={{ cursor: "pointer", display:'flex', marginTop:'2px' }}
      >
        {GetPlayerTypeBlock(selectedOption.value, selectedOption.value, pColors)}
        <div style={{ marginTop:'-4px' }}>{selectedOption.label || "Select Player Type"}</div>
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          {options.map((option) => (
            <div
              className="easyseat-dropdown-menu-item"
              key={option.value}
              onClick={() => handleOptionClick(option)}
            >
              {GetPlayerTypeBlock(option.value, option.value, pColors)}
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlayerTypeFormSelect;
