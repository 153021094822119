const moment = require("moment");
import {
  BLINDS,
  TT_VPIP,
  TT_DB,
  TT_CallTIME,
  TT_BP1,
  TT_BP3,
  TT_BP5,
  TT_BP7,
  PLO,
  PLO5,
  PLO6,
  NLHE,
  S888POKER,
  WINAMAX,
  AMERICASCARDROOM,
} from "constant/constant";

export const GetRealStakeWithDollar = (value) => {
  var tmplist = value.split("/");
  return `$${tmplist[0]}/${tmplist[1]}`;
};

export const IsInteger = (value) => {
  var x = parseFloat(value);
  return !isNaN(value) && (x | 0) === x;
};

export const GetAccessableSiteUnionClub = (access_site, site_unions_club) => {
  let ret = [];

  site_unions_club.forEach((ele) => {
    let tval = {};
    if (ele.site_unions.length === 0) {
      if (IsValidNoWithUnionUserAccess(ele.site_name, access_site)) {
        tval.site_name = ele.site_name;
        tval.site_unions = ele.site_unions;
        ret.push(tval);
      }
    } else {
      tval.site_name = ele.site_name;
      tval.site_unions = [];
      ele.site_unions.forEach((ele1) => {
        let ttval = { union_name: "", union_clubs: { club_name: [] } };
        ele1.union_clubs.club_name.forEach((ele2) => {
          if (
            IsValidClubUserAccess(
              ele.site_name,
              ele1.union_name,
              ele2,
              access_site
            )
          ) {
            ttval.union_name = ele1.union_name;
            ttval.union_clubs.club_name.push(ele2);
            tval.site_unions.push(ttval);
          }
        });
      });
      if (tval.site_unions.length !== 0) {
        ret.push(tval);
      }
    }
  });
  return ret;
};

export const GetEasySeatJSONObject = (data) => {
  let ret = {};

  ret["id"] = data["id"];
  ret["created_at"] = GetTimeCreatedAt(data["created_at"]);
  ret["nid"] = data["nid"];
  ret["nickname"] = data["nickname"];
  ret["hiddenname"] = data["hiddenname"];
  ret["position"] = data["position"];
  ret["gametype"] = data["gametype"];
  ret["maxtablesize"] = data["maxtablesize"];
  ret["site"] = data["site"];
  ret["union"] = data["union"];
  ret["playertype"] = data["playertype"];
  ret["tablename"] = data["tablename"];
  ret["tabletype"] = data["tabletype"].split(",");
  ret["emptyseats"] = data["emptyseats"].split(",");
  ret["nicknames"] = data["nicknames"].split(",");
  ret["positions"] = data["positions"].split(",");
  ret["blinds"] = data["blinds"];
  ret["nids"] = data["nids"].split(",");
  ret["realstack"] = "";
  let ptypes = {};

  ret["nids"].forEach((val) => {
    ptypes[val] = "unknown";
  });

  ret["playertypes"] = ptypes;
  if (data["bombpothand"] === undefined || data["bombpothand"] === null) {
    return ret;
  } else {
    ret["bombpothand"] = data["bombpothand"];
    return ret;
  }
};

export const GetDefaultAdvMinBuddyProfileSetting = (siteUnionsClubs) => {
  let indx = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  let ret = [];

  siteUnionsClubs.forEach((val) => {
    let tmp = {};
    tmp["site_name"] = val["site_name"];
    tmp["clubs"] = [];
    if (val["site_unions"].length !== 0) {
      val["site_unions"].forEach((val2) => {
        val2["union_clubs"]["club_name"].forEach((val3) => {
          let tm = {};
          tm["name"] = val3;
          tm["stake"] = [];
          indx.forEach(() => {
            let sk = {};
            sk["mbuddy1"] = 0;
            sk["mbuddy2"] = 0;
            tm["stake"].push(sk);
          });
          tmp["clubs"].push(tm);
        });
      });
      ret.push(tmp);
    } else {
      tmp["stake"] = [];
      indx.forEach(() => {
        let sk = {};
        sk["mbuddy1"] = 0;
        sk["mbuddy2"] = 0;
        tmp["stake"].push(sk);
      });
      ret.push(tmp);
    }
  });

  return ret;
};

export const GetNewDefaultProfile = (user_nid, name, siteClubs) => {
  let newProfile = {
    user_nid: user_nid,
    name: name,
    tabletype: "0,1,2,3",
    playertype: "1,1,1,1,1",
    blinds: `0-${BLINDS.length - 1},0-${BLINDS.length - 1},0-${
      BLINDS.length - 1
    },0-${BLINDS.length - 1},0-${BLINDS.length - 1}`,
    siteclubs: JSON.stringify(GetDefaultSiteClubsProfile(siteClubs)),
    empty_seat_offset: "1,1,1,1,1",
    min_player_type: "1,1,1,1,1",
    avdsetting: JSON.stringify(GetDefaultAdvMinBuddyProfileSetting(siteClubs)),
    gametype: "0,1,2,3",
    maxmin_players: JSON.stringify(GetDefaultMaxMinPlayers()),
  };
  return newProfile;
};

export const GetDefaultMaxMinPlayers = () => {
  let ret = {};
  ret["max"] = 6;
  ret["min"] = 1;
  return ret;
};

export const IsValidNoWithUnionUserAccess = (site_name, access_site) => {
  let ret = false;
  access_site.forEach((ele) => {
    let vlist = ele.split("|");
    if (vlist.length === 1 && vlist[0] === site_name) {
      ret = true;
    }
  });
  return ret;
};

export const IsValidClubUserAccess = (site, union, club, access_site) => {
  let ret = false;
  access_site.forEach((ele) => {
    if (ele === `${site}|${union}|${club}`) {
      ret = true;
    }
  });
  return ret;
};

export const GetDefaultSiteClubsProfile = (siteClubs) => {
  let retlist = [];
  let sites = GetSites(siteClubs);
  sites.forEach((val) => {
    let ret = {};
    ret["site_name"] = val["value"];
    ret["club_names"] = [];
    let clubs = GetClubs(siteClubs, ret["site_name"]);
    if (clubs.length == 0) {
      ret["club_names"].push(val["value"]);
    } else {
      clubs.forEach((val1) => {
        ret["club_names"].push(val1["value"]);
      });
    }
    retlist.push(ret);
  });
  return retlist;
};

export const GetDefaultAdvSettingProfile = (siteClubs) => {
  let retlist = [];
  let sites = GetSites(siteClubs);
  sites.forEach((val) => {
    let ret = {};
    ret["site_name"] = val["value"];
    ret["club_names"] = [];
    let clubs = GetClubs(siteClubs, ret["site_name"]);
    clubs.forEach((val1) => {
      ret["club_names"].push(val1["value"]);
    });
    retlist.push(ret);
  });
  return retlist;
};

export const UpdateGameTypeObjectFromMulSel = (gametypes, values) => {
  let ret = [
    {
      value: 0,
      text: PLO,
      selected: false,
    },
    {
      value: 1,
      text: PLO5,
      selected: false,
    },
    {
      value: 2,
      text: PLO6,
      selected: false,
    },
    {
      value: 3,
      text: NLHE,
      selected: false,
    },
  ];

  gametypes.forEach((v1) => {
    ret.forEach((v2) => {
      if (v2["value"] === v1["value"]) {
        v1["selected"] = false;
      }
    });
  });

  gametypes.forEach((v1) => {
    values.forEach((v2) => {
      if (v2["value"] === v1["value"]) {
        v1["selected"] = true;
      }
    });
  });
  console.log(gametypes);
  return ret;
};

export const GetGameTypeObjectFromMulSel = (gametypes) => {
  let ret = [
    {
      value: 0,
      text: PLO,
      selected: false,
    },
    {
      value: 1,
      text: PLO5,
      selected: false,
    },
    {
      value: 2,
      text: PLO6,
      selected: false,
    },
    {
      value: 3,
      text: NLHE,
      selected: false,
    },
  ];
  ret.forEach((v1) => {
    gametypes.forEach((v2) => {
      if (v2["value"] === v1["value"]) {
        v1["selected"] = true;
      }
    });
  });
  return ret;
};

export const GetTableTypeObjectFromMulSel = (tabletypes) => {
  let ret = [
    {
      value: 0,
      text: TT_VPIP,
      selected: false,
    },
    {
      value: 1,
      text: TT_DB,
      selected: false,
    },
    {
      value: 2,
      text: TT_CallTIME,
      selected: false,
    },
    {
      value: 3,
      text: TT_BP1,
      selected: false,
    },
    {
      value: 4,
      text: TT_BP3,
      selected: false,
    },
    {
      value: 5,
      text: TT_BP5,
      selected: false,
    },
    {
      value: 6,
      text: TT_BP7,
      selected: false,
    },
  ];
  tabletypes.forEach((v1) => {
    ret.forEach((v2) => {
      if (v2["value"] === v1["value"]) v2["selected"] = true;
    });
  });
  return ret;
};

export const GetSites = (data) => {
  let ret = [];
  data.forEach((val) => {
    if (val["site_unions"].length !== 0) {
      let tm = {};
      tm["value"] = val["site_name"];
      tm["label"] = val["site_name"];
      ret.push(tm);
    } else {
      let tm = {};
      tm["value"] = val["site_name"];
      tm["label"] = val["site_name"];
      ret.push(tm);
      // if (val["site_name"] === WINAMAX) {
      //   let tm = {};
      //   tm["value"] = val["site_name"];
      //   tm["label"] = val["site_name"];
      //   ret.push(tm);
      // }
      // if (val["site_name"] === S888POKER) {
      //   let tm = {};
      //   tm["value"] = val["site_name"];
      //   tm["label"] = val["site_name"];
      //   ret.push(tm);
      // }
      // if (val["site_name"] === AMERICASCARDROOM) {
      //   let tm = {};
      //   tm["value"] = val["site_name"];
      //   tm["label"] = val["site_name"];
      //   ret.push(tm);
      // }
    }
  });
  return ret;
};

export const GetSitesNoUnions = (data) => {
  let ret = [];
  data.forEach((val) => {
    if (val["site_unions"].length === 0) {
      let tm = {};
      tm["value"] = val["site_name"];
      tm["label"] = val["site_name"];
      ret.push(tm);
    }
  });
  return ret;
};

export const GetTimeCreatedAt = (datetime) => {
  return moment(datetime).format("HH:mm:ss");
};

export const GetClubs = (data, site) => {
  let ret = [];
  data.forEach((val) => {
    if (site === val["site_name"]) {
      val["site_unions"].forEach((val2) => {
        val2["union_clubs"]["club_name"].forEach((val3) => {
          let tm = {};
          tm["label"] = val3;
          tm["value"] = val3;
          ret.push(tm);
        });
      });
    }
  });
  return ret;
};

export const GetUnionBySiteAndClub = (siteUnionsClubs, site, club) => {
  let ret = "";
  siteUnionsClubs.forEach((val) => {
    if (site === val["site_name"]) {
      val["site_unions"].forEach((val2) => {
        if (val2["union_clubs"]["club_name"].includes(club)) {
          ret = val2["union_name"];
        }
      });
    }
  });
  return ret;
};

export const SetAdvMinBuddyValue = (
  advsetting,
  site,
  club,
  st_ind,
  minb1,
  minb2
) => {
  advsetting.forEach((val) => {
    if (val["site_name"] === site) {
      if (val["clubs"].length === 0) {
        val["stake"][st_ind]["mbuddy1"] = minb1;
        val["stake"][st_ind]["mbuddy2"] = minb2;
      } else {
        val["clubs"].forEach((val1) => {
          if (val1["name"] === club) {
            val1["stake"][st_ind]["mbuddy1"] = minb1;
            val1["stake"][st_ind]["mbuddy2"] = minb2;
          }
        });
      }
    }
  });
};

export const GetClubsProfileSetting = (data, site, siteclubs) => {
  let ret = [];

  data.forEach((val) => {
    if (site === val["site_name"]) {
      val["site_unions"].forEach((val2) => {
        val2["union_clubs"]["club_name"].forEach((val3) => {
          let tm = {};
          tm["text"] = val3;
          tm["value"] = val3;
          tm["selected"] = false;
          ret.push(tm);
        });
      });
    }
  });

  siteclubs.forEach((val) => {
    if (site == val["site_name"]) {
      val["club_names"].forEach((val1) => {
        ret.forEach((val3) => {
          if (val1 === val3["value"]) {
            val3["selected"] = true;
          }
        });
      });
    }
  });
  return ret;
};

export const GetClubsProfileSettingNoUnion = (
  data,
  sites_nounions,
  siteclubs
) => {
  let ret = [];
  sites_nounions.forEach((ele) => {
    data.forEach((val) => {
      if (ele["value"] === val["site_name"]) {
        let tm = {};
        tm["text"] = val["site_name"];
        tm["value"] = val["site_name"];
        tm["selected"] = false;
        ret.push(tm);
      }
    });
  });
  siteclubs.forEach((val) => {
    ret.forEach((val1) => {
      if (val1["value"] === val["site_name"]) {
        if (val["club_names"].length === 1) {
          val1["selected"] = true;
        }
      }
    });
  });
  return ret;
};

export const GetAdvClubsProfileSetting = (site, siteclubs) => {
  let ret = [];

  siteclubs.forEach((val) => {
    if (site == val["site_name"]) {
      val["club_names"].forEach((val1) => {
        let tm = {};
        tm["value"] = val1;
        tm["label"] = val1;
        ret.push(tm);
      });
    }
  });
  return ret;
};

export const GetAdvMinBuddyValue = (advsetting, site, club, st_ind) => {
  let ret = { mbuddy1: 0, mbuddy2: 0 };

  advsetting.forEach((val) => {
    if (val["site_name"] === site) {
      if (val["clubs"].length === 0) {
        ret = val["stake"][st_ind];
      } else {
        val["clubs"].forEach((val1) => {
          if (val1["name"] === club) {
            ret = val1["stake"][st_ind];
          }
        });
      }
    }
  });
  return ret;
};
