import React, { useState } from 'react';

const TGColorPicker = () => {
  // Step 1: Define an array of 7 colors
  const colors = ['#FF5733', '#33FF57', '#5733FF', '#F1C40F', '#E74C3C', '#8E44AD', '#3498DB'];
  
  // Step 2: Create a state for the selected color
  const [selectedColor, setSelectedColor] = useState(colors[0]);

  // Step 3: Handle color selection
  const handleColorClick = (color) => {
    setSelectedColor(color);
    // You can also add additional functionality here
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2>Choose a Color</h2>
      <div style={{ display: 'flex', gap: '10px' }}>
        {colors.map((color) => (
          <div
            key={color}
            onClick={() => handleColorClick(color)}
            style={{
              backgroundColor: color,
              width: '50px',
              height: '50px',
              cursor: 'pointer',
              border: selectedColor === color ? '2px solid black' : 'none',
              borderRadius: '5px',
            }}
          />
        ))}
      </div>
      <h3 style={{ marginTop: '20px' }}>Selected Color: <span style={{ color: selectedColor }}>{selectedColor}</span></h3>
    </div>
  );
};

export default TGColorPicker;