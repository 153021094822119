import { CMultiSelect } from "@coreui/react-pro";
import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function AppClubsMultiSelector({
  disableProfile,
  options,
  siteName,
  ClubMulSelOnChange,
}) {
  const multiSelectRef = useRef(null);

  const handleChange = (a) => {
    ClubMulSelOnChange(a, siteName);
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.classList.contains(
        "form-multi-select-option-with-checkbox"
      ) &&
      !event.target.classList.contains("form-multi-select-all")
    ) {
      if (!multiSelectRef.current.classList.contains("show")) {
        multiSelectRef.current.classList.add("show");
        multiSelectRef.current.setAttribute("aria-expanded", true);
      } else {
        multiSelectRef.current.classList.remove("show");
        multiSelectRef.current.setAttribute("aria-expanded", false);
      }
    }
  };
  return (
    <div
      style={{ position: "relative", display: "flex", alignItems: "center" }}
    >
      <div
        onClick={handleClickOutside}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          position: "relative",
          width: "100%",
        }}
      >
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            position: "absolute",
            right: "10px",
            marginTop:"18px",
            color: "#636f83",
            zIndex: 100,
            background: "#2f303a",
          }}
        />
        <div style={{ cursor: "pointer", width: "100%" }}>
          <div style={{marginTop:'-8px', marginBottom:'4px'}}>{siteName}</div>
          <CMultiSelect
            style={{ cursor: "pointer", width: "100%" }}
            ref={multiSelectRef}
            options={options}
            disabled={disableProfile}
            selectionType="tags"
            optionsStyle="checkbox"
            selectAll={true}
            selectAllLabel="Select all"
            placeholder="Please select the game types..."
            search={false}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
