import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { CBadge } from "@coreui/react-pro";

export const AppSidebarNav = ({ items }) => {

  const user = useSelector((state) => state.login.user);
  const location = useLocation();
  const [telegram, SetTelegram] = useState(true);

  useEffect(()=>{
    if(user[0].type !== "0"){
      if(user[0].telegram_enable == "0"){
        SetTelegram(true);
      }else{
        SetTelegram(false);
      }
    }
  },[telegram]);

  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item;
    const Component = component;
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, index) =>{
            item.items ? navGroup(item, index) : navItem(item, index)
        }
        )}
      </Component>
    );
  };

  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    );
  };

  const navItem = (item, index) => {
    if(item.name === "Telegram Setting"){
        if(telegram){
          const { component, name, badge, icon, ...rest } = item;
          const Component = component;
          return (
            <Component
              {...(rest.to &&
                !rest.items && {
                  component: NavLink,
                })}
              key={index}
              {...rest}
            >
              {navLink(name, icon, badge)}
            </Component>
          );
        }
    }else{
      const { component, name, badge, icon, ...rest } = item;
      const Component = component;
      return (
        <Component
          {...(rest.to &&
            !rest.items && {
              component: NavLink,
            })}
          key={index}
          {...rest}
        >
          {navLink(name, icon, badge)}
        </Component>
      );
    }

  };

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
    </React.Fragment>
  );
};

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
