import React, { useState, useEffect, useRef } from "react";
import siteClubGGLogo from "../../../assets/logos/clubgg.png";
import siteWinaLogo from "../../../assets/logos/winamax.png";
import siteAmericasLogo from "../../../assets/logos/americas.png";
import siteGgpbLogo from "../../../assets/logos/ggpokerblack.png";
import site888PokerLogo from "../../../assets/logos/888poker.png";
import sitePkstarLogo from "../../../assets/logos/pokerstarslogo.png";
import sitePPPLogo from "../../../assets/logos/ppp_logo.png";
import {
  PPP,
  WINAMAX,
  CLUBGG,
  POKERSTARS,
  S888POKER,
  AMERICASCARDROOM,
} from "constant/constant";
import { CFormLabel } from "@coreui/react-pro";

const SiteFormSelect = ({ label, options, onChange }) => {
  console.log(options);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const GetLogoImage = (site) => {
    switch (site) {
      case PPP:
        return sitePPPLogo;
      case WINAMAX:
        return siteWinaLogo;
      case CLUBGG:
        return siteClubGGLogo;
      case POKERSTARS:
        return sitePkstarLogo;
      case S888POKER:
        return site888PokerLogo;
      case AMERICASCARDROOM:
        return siteAmericasLogo;
      default:
        return sitePPPLogo;
    }
  };

  const GetLogoImgElement = (site) => {
    return (
      <img
        src={GetLogoImage(site)}
        style={{ width: "20px", height: "20px", marginRight: "8px" }}
        alt={site}
      />
    );
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: "relative", width: "100%" }} ref={dropdownRef}>
      <CFormLabel>{label}</CFormLabel>
      <div
        className="form-select"
        onClick={toggleDropdown}
        style={{ cursor: "pointer" }}
      >
        {GetLogoImgElement(selectedOption.value)}
        <CFormLabel style={{ marginBottom: "0px" }}>
          {selectedOption.label}
        </CFormLabel>
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          {options.map((option) => (
            <div
              className="easyseat-dropdown-menu-item"
              key={option.value}
              onClick={() => handleOptionClick(option)}
            >
              {GetLogoImgElement(option.value)}
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SiteFormSelect;
