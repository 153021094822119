import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    CCard,
    CCardHeader,
    CRow,
    CCol,
    CCardBody,
    CFormLabel,
    CButton,
    CFormSwitch,
    CInputGroup,
    CFormInput,
    CLoadingButton,
    CFormText,
    CInputGroupText,
    CFormSelect,
} from "@coreui/react-pro";
import { SketchPicker } from "react-color";
import {
    GetPlayerColors,
    GetProfiles,
    InsertPlayerColors,
    UpdatePlayerColors,
} from "../../helpers/supabase";
import { GetProfileNames, GetTelegramColor, history } from "helpers";
import FontAwesome from "react-fontawesome";
import {
    BUDDY,
    LIGHTBUDDY,
    MEGABUDDY,
    REGULAR,
    TELEGRAM_BOT_TOKEN,
    ULTRABUDDY,
    UNKNOWN,
} from "constant/constant";
import axios from 'axios';
import { GetTelegramAdminSetting, GetTelegramSettingByUserId, InsertTelegramSetting, UpdateTelegramSetting, UpdateUserProfileName } from "helpers/admin/supabase";
import { ShowFailToast, ShowSuccessToast, ShowWarningToast } from "helpers/toast-alert";
import TGColorPicker from "./customizecomponent/TGColorPicker";

const TelegramSettingPage = () => {

    const dispatch = useDispatch();
    const user_type = useSelector((state) => state.login.user[0].type);
    const user_id = useSelector((state) => state.login.user[0].id);
    const telegramSetting = useSelector((state) => state.telegramSettingState.telegramSetting);
    const profiles = useSelector((x) => x.profileState.profiles);

    const curTeleProfileIndex = useSelector(
        (x) => x.profileState.currentTeleProfileIndex
    );
    const user = useSelector((state) => state.login.user[0]);
    const [updating, SetUpdating] = useState(false);
    const [selectType, SetSelectType] = useState(null);
    const [fillColor, SetFillColor] = useState(null);

    const [telegramToken, SetTelegramToken] = useState(telegramSetting.telegram_token);
    const [telegramChatId, SetTelegramChatId] = useState(telegramSetting.telegram_chatid);
    const [telegramAccount, SetTelegramAccount] = useState(telegramSetting.telegram_name);
    const [telegramColors, SetTelegramColors] = useState(telegramSetting.telegram_colors);
    const [enableTelegramSend, SetEnableTelegramSend] = useState(telegramSetting.telegram_alert === "0" ? true : false);

    const colors = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'brown', 'white', 'black'];
    const [selectedColor, setSelectedColor] = useState(colors[0]);

    const handleColorClick = (color) => {
        setSelectedColor(color);
        let tcolors = telegramColors.split(',');
        let typeIndex = 0;
        switch (selectType) {
            case REGULAR:
                typeIndex = 0;
                break;
            case UNKNOWN:
                typeIndex = 1;
                break;
            case LIGHTBUDDY:
                typeIndex = 2;
                break;
            case BUDDY:
                typeIndex = 3;
                break;
            case MEGABUDDY:
                typeIndex = 4;
                break;
            case ULTRABUDDY:
                typeIndex = 5;
                break;
        }
        let cindex = colors.indexOf(color);
        tcolors[typeIndex] = `${cindex}`;
        SetTelegramColors(tcolors.join(","));
        telegramSetting.telegram_colors = tcolors.join(",");
        UpdateTelegramSetting(telegramSetting).then((ret) => {
            if (ret === 1) {
                dispatch({ type: "set_telegram_setting", payload: telegramSetting });
            }
        });
        SetSelectType(null);
    };

    // Function to get the chat ID
    const getChatId = async () => {
        try {

            const url = `https://api.telegram.org/bot${telegramToken}/getUpdates`;
            const response = await axios.get(url);
            if (response.data.ok && response.data.result.length > 0) {
                const chatId = getTelegramChatID(response.data);
                if (chatId !== 0) {
                }
                return chatId;
            }
            return "1";
        } catch (error) {
            console.error('Error getting updates:', error);
            return "1";
        }
    };

    const getTelegramChatID = (data) => {
        for (var i = 0; i < data.result.length; i++) {
            if (telegramAccount === data.result[i].message.chat.username) {
                return data.result[i].message.chat.id;
            }
        }
        return 0;
    }

    useEffect(() => {

        if (history.location.pathname.includes("dashboard")) {
            dispatch({ type: "set", showProfile: true });
        } else {
            dispatch({ type: "set", showProfile: false });
        }
        SetTelegramToken(telegramSetting.telegram_token);
        SetTelegramChatId(telegramSetting.telegram_chatid);
        SetTelegramAccount(telegramSetting.telegram_name);
        SetEnableTelegramSend(telegramSetting.telegram_alert === "0" ? true : false);
        SetTelegramColors(telegramSetting.telegram_colors);

    }, [dispatch, telegramSetting, profiles]);

    const ValidateTelegramAccount = () => {

        if (telegramAccount === "") {
            return false;
        }
        if (telegramAccount.length === 0) {
            return false;
        }
        return true;
    }

    const pickerTelegramColorOnClick = (type) => {
        // SetFillColor(GEtCol);
        if (selectType) {
            SetSelectType(null);
        } else {
            SetSelectType(type);
        }
    }

    const UpdateBtnOnClick = () => {
        SetUpdating(true);
        if (!ValidateTelegramAccount()) {
            ShowWarningToast("The name of telegram account is not correct now. please confirm again.");
            return;
        }
        getChatId().then((ret) => {
            let setting = {
                id: telegramSetting.id,
                user_id: user_id,
                telegram_name: telegramAccount,
                telegram_alert: enableTelegramSend ? "0" : "1",
                telegram_chatid: "",
                telegram_admin: `${telegramSetting.telegram_admin}`,
                telegram_colors: "1,2,3,4,5,6"
            }
            if (ret) {
                setting = {
                    id: telegramSetting.id,
                    user_id: user_id,
                    telegram_name: telegramAccount,
                    telegram_alert: enableTelegramSend ? "0" : "1",
                    telegram_chatid: ret,
                    telegram_token: telegramToken,
                    telegram_admin: `${telegramSetting.telegram_admin}`,
                    telegram_colors: telegramSetting.telegram_colors
                }
                SetTelegramChatId(ret);
            } else {
                SetTelegramChatId("");
            }
            UpdateTelegramSetting(setting).then((ret) => {
                if (ret === 1) {
                    ShowSuccessToast(
                        `Successfully Updating the Telegram setting! The Telegram setting has been updated just now.`
                    );
                    dispatch({ type: "set_telegram_setting", payload: setting });
                } else {
                    ShowFailToast(
                        `Updating the Telegram setting Failed!`
                    );
                }
                SetUpdating(false);
            })
        })

    }

    const SelectProfileOnChange = (a) => {
        user.telegram_profile_name = profiles[a.target.value]["name"];
        UpdateUserProfileName(user).then((val) => { });
        dispatch({ type: "set_current_profile_tele_index", payload: a.target.value });
    };

    const GetTelegramButtonColor = (playerType, iTelegramColors) => {
        let colorIndex = GetTelegramColorIndexByPlayerType(playerType, iTelegramColors);
        let color = colors[colorIndex];
        return color;
    }

    const GetTelegramColorIndexByPlayerType = (playerType, iTelegramColors) => {
        let typeIndex = 0;
        switch (playerType) {
            case REGULAR:
                typeIndex = 0;
                break;
            case UNKNOWN:
                typeIndex = 1;
                break;
            case LIGHTBUDDY:
                typeIndex = 2;
                break;
            case BUDDY:
                typeIndex = 3;
                break;
            case MEGABUDDY:
                typeIndex = 4;
                break;
            case ULTRABUDDY:
                typeIndex = 5;
                break;
        }
        let tcolors = iTelegramColors.split(",");
        let colorIndex = parseInt(tcolors[typeIndex]);
        return colorIndex;
    }

    const getDOMElement = () => {
        return (
            <CCard className="mb-2 mt-2">
                <CCardHeader>
                    <strong>Set A Color For Each Player In Telegram</strong>
                </CCardHeader>
                <CCardBody className="easyseat-text-align-left">
                    <CRow>
                        <CFormLabel
                            style={{
                                width: "40%",
                                display: "inline-block",
                                marginLeft: "20%",
                            }}
                        >
                            <strong>Unknown : </strong>
                        </CFormLabel>
                        <CButton
                            style={{
                                backgroundColor: GetTelegramButtonColor(UNKNOWN, telegramColors),
                                width: "25px",
                                height: "25px",
                                display: "inline-block",
                            }}
                            onClick={() => {
                                pickerTelegramColorOnClick(UNKNOWN);
                            }}
                        ></CButton>
                    </CRow>
                    <CRow>
                        <CFormLabel
                            style={{
                                width: "40%",
                                display: "inline-block",
                                marginLeft: "20%",
                            }}
                        >
                            <strong>Light Buddy : </strong>
                        </CFormLabel>
                        <CButton
                            style={{
                                backgroundColor: GetTelegramButtonColor(LIGHTBUDDY, telegramColors),
                                width: "25px",
                                height: "25px",
                                display: "inline-block",
                            }}
                            onClick={() => {
                                pickerTelegramColorOnClick(LIGHTBUDDY);
                            }}
                        ></CButton>
                    </CRow>
                    <CRow>
                        <CFormLabel
                            style={{
                                width: "40%",
                                display: "inline-block",
                                marginLeft: "20%",
                            }}
                        >
                            <strong>Buddy : </strong>
                        </CFormLabel>
                        <CButton
                            style={{
                                backgroundColor: GetTelegramButtonColor(BUDDY, telegramColors),
                                width: "25px",
                                height: "25px",
                                display: "inline-block",
                            }}
                            onClick={() => {
                                pickerTelegramColorOnClick(BUDDY);
                            }}
                        ></CButton>
                    </CRow>
                    <CRow>
                        <CFormLabel
                            style={{
                                width: "40%",
                                display: "inline-block",
                                marginLeft: "20%",
                            }}
                        >
                            <strong>Mega Buddy : </strong>
                        </CFormLabel>
                        <CButton
                            style={{
                                backgroundColor: GetTelegramButtonColor(MEGABUDDY, telegramColors),
                                width: "25px",
                                height: "25px",
                                display: "inline-block",
                            }}
                            onClick={() => {
                                pickerTelegramColorOnClick(MEGABUDDY);
                            }}
                        ></CButton>
                    </CRow>
                    <CRow>
                        <CFormLabel
                            style={{
                                width: "40%",
                                display: "inline-block",
                                marginLeft: "20%",
                            }}
                        >
                            <strong>Ultra Buddy : </strong>
                        </CFormLabel>
                        <CButton
                            style={{
                                backgroundColor: GetTelegramButtonColor(ULTRABUDDY, telegramColors),
                                width: "25px",
                                height: "25px",
                                display: "inline-block",
                            }}
                            onClick={() => {
                                pickerTelegramColorOnClick(ULTRABUDDY);
                            }}
                        ></CButton>
                    </CRow>
                    {GetColorPickerForm()}
                </CCardBody>
            </CCard>
        );
    };

    const GetColorPickerForm = () => {
        if (selectType) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '32px' }}>
                    <h5>Choose a Color</h5>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {colors.map((color) => (
                            <div
                                key={color}
                                onClick={() => handleColorClick(color)}
                                style={{
                                    backgroundColor: color,
                                    width: '42px',
                                    height: '42px',
                                    cursor: 'pointer',
                                    borderRadius: '5px',
                                }}
                            />
                        ))}
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="align-items-center" style={{ maxWidth: "800px" }}>
            <CRow>
                <CCol>
                    <CCard className="mb-1 mt-1">
                        <CCardHeader>
                            <strong>Telegram Alert Setting</strong>
                        </CCardHeader>
                        <CCardBody className="easyseat-text-align-left">
                            {parseInt(user_type) === 0 &&
                                <CInputGroup
                                    className="flex-nowrap mb-2"
                                    style={{ marginTop: "22px" }}
                                >
                                    <CInputGroupText id="basic-addon3">Telegram Bot Token:</CInputGroupText>
                                    <CFormInput
                                        type="text"
                                        value={telegramToken}
                                        placeholder="Please Enter your Telegram Token (e.x 7889212935:AAFU...)."
                                        onChange={(ret) => {
                                            SetTelegramToken(ret.target.value.trim());
                                        }}
                                        required
                                    />
                                </CInputGroup>
                            }
                            <CInputGroup
                                className="flex-nowrap mb-2"
                                style={{ marginTop: "22px" }}
                            >
                                <CInputGroupText id="basic-addon3">Telegram Account Name:</CInputGroupText>
                                <CFormInput
                                    type="text"
                                    value={telegramAccount}
                                    placeholder="Please Enter your Telegram Account Name (e.x @golcoco)."
                                    onChange={(ret) => {
                                        SetTelegramAccount(ret.target.value.trim());
                                    }}
                                    required
                                />
                            </CInputGroup>
                            <CInputGroup
                                className="flex-nowrap mb-2"
                                style={{ marginTop: "22px" }}
                            >
                                <CInputGroupText id="basic-addon3">Telegram Account ChatId:</CInputGroupText>
                                <CFormInput
                                    type="text"
                                    value={telegramChatId}
                                    readOnly={true}
                                    placeholder="No Set Chat ID. You can not get the alert on your telegram account yet."
                                    required
                                />
                            </CInputGroup>

                            <CInputGroup
                                className="flex-nowrap mb-2"
                                style={{ marginTop: "22px" }}
                            >
                                <CInputGroupText id="basic-addon3">Telegram Profile:</CInputGroupText>
                                <CFormSelect
                                    options={GetProfileNames(profiles)}
                                    value={curTeleProfileIndex}
                                    onChange={SelectProfileOnChange}
                                ></CFormSelect>
                            </CInputGroup>



                            <CFormSwitch
                                className="mt-3 mb-1"
                                size="lg"
                                label="Enable to Send Alerts to Telegram Account"
                                checked={enableTelegramSend}
                                onChange={(value) => {
                                    SetEnableTelegramSend(value.target.checked)
                                }}
                            />

                            <CLoadingButton
                                className="mt-3"
                                color="info"
                                loading={updating}
                                style={{ marginLeft: "60%", width: "40%" }}
                                onClick={UpdateBtnOnClick}
                            >
                                Update
                            </CLoadingButton>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    {getDOMElement()}
                </CCol>
            </CRow>
        </div>
    );
};
export default React.memo(TelegramSettingPage);
