import React from "react";

const ServerStatusPage = () => {
  return (
    <iframe
      className="easyseat-iframe "
      title="brutal.rs"
      src="https://status.brutal.rs"
      // src="https://www.pcgamespunch.com"
      sandbox="allow-scripts allow-same-origin allow-popups"
    >
    </iframe>
  );
};
export default React.memo(ServerStatusPage);
