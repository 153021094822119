
const initialState = {
  playerColors:{
    user_nid:"1",
    regular: "#ed761e",
    unknown: "white",
    lightbuddy: "yellow",
    buddy: "green",
    megabuddy:"turquoise",
    ultrabuddy:"purple"
  }
}
  
export const playerColorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'set_player_color':
        return {
          ...state,
          playerColors : action.payload,
      }
      default:
        return state
    }
}