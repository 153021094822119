import React, { useState, useEffect } from "react";
import {
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CListGroup,
  CListGroupItem,
} from "@coreui/react-pro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { SUPA_PLAYER_TABLE } from "constant/constant";
import { supaClient } from "helpers";
import { useSelector } from "react-redux";
import {
  CheckIfInteger,
  ConvertLowercase,
  GetLogoImgElement,
  GetPlayerTypeBlock,
} from "helpers/utils";

const PlayerSearchForm = ({
  searchTerm,
  setSearchTerm,
  onPlayerSelect,
  sites,
}) => {
  const [players, setPlayers] = useState([]);
  const pColors = useSelector((state) => state.playerColorState.playerColors);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    if (searchTerm) {
      fetchPlayers(searchTerm);
    } else {
      setPlayers([]);
    }
  }, [searchTerm]);

  const fetchPlayers = debounce(async (searchTerm) => {
    if (!CheckIfInteger(searchTerm)) {
      const { data, error } = await supaClient
        .from(SUPA_PLAYER_TABLE)
        .select("*")
        .ilike("nickname", `%${ConvertLowercase(searchTerm)}%`);

      // Fix the filter function
      const tdata = data.filter((player) => IsValidForSite(player));
      
      const pPlayers = tdata.filter((player) =>
        ConvertLowercase(player.nickname).startsWith(ConvertLowercase(searchTerm))
      );
      const oPlayers = tdata.filter(
        (player) => !ConvertLowercase(player.nickname).startsWith(ConvertLowercase(searchTerm))
      );

      const sortedPlayers = [...pPlayers, ...oPlayers];

      if (error) {
        console.error("Error fetching players:", error);
      } else if (searchTerm) {
        setPlayers(sortedPlayers);
      }
    } else {
      const { data, error } = await supaClient
      .from(SUPA_PLAYER_TABLE)
      .select("*")
      .ilike("nid", `%${ConvertLowercase(searchTerm)}%`);

    // Fix the filter function
    const tdata = data.filter((player) => IsValidForSite(player));
    
    const pPlayers = tdata.filter((player) =>
      player.nid.startsWith(ConvertLowercase(searchTerm))
    );

    const oPlayers = tdata.filter(
      (player) => !player.nid.startsWith(ConvertLowercase(searchTerm))
    );

    const sortedPlayers = [...pPlayers, ...oPlayers];

    if (error) {
      console.error("Error fetching players:", error);
    } else if (searchTerm) {
      setPlayers(sortedPlayers);
    }
    }
  }, 300);

  const IsValidForSite = (player) => {
    return sites.some((ele) => ele.value === player.site);
  };

  const getPlayerListItemElemment = (data) => {
    return (
      <div style={{ display: "inline-flex" }}>
        {GetLogoImgElement(data.site)}
        {GetPlayerTypeBlock(data.id, data.playertype, pColors)}
        <div
          style={{ marginTop: "-4px", marginLeft: "12px", minWidth: "120px" }}
        >
          {data.nid}
        </div>
        <div style={{ marginTop: "-4px", marginLeft: "32px" }}>
          {data.nickname}
        </div>
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <CInputGroup className="flex-nowrap mb-2" style={{ marginTop: "22px" }}>
        <CInputGroupText id="addon-wrapping">
          <FontAwesomeIcon icon={faSearch} />
        </CInputGroupText>
        <CFormInput
          type="text"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          placeholder="Search player..."
        />
      </CInputGroup>
      {searchTerm !== "" && players.length > 0 && (
        <CListGroup style={{ marginTop: "-4px" }}>
          {players
            .slice(0, Math.min(players.length, 6))
            .map((player, index) => (
              <CListGroupItem
                className="easyseat-list-group-item"
                key={index}
                onClick={() => onPlayerSelect(player)}
              >
                {getPlayerListItemElemment(player)}
              </CListGroupItem>
            ))}
        </CListGroup>
      )}
      {players.length === 0 && searchTerm && (
        <CListGroup style={{ marginTop: "-4px" }}>
          <CListGroupItem>No players found</CListGroupItem>
        </CListGroup>
      )}
    </div>
  );
};

export default PlayerSearchForm;
