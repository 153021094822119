import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "helpers";
import { GetEasySeatJSONObject } from "helpers/trasform";
import EasySeatItemRowWidgetNew from "components/subcomponents/EasySeatItemRowWidget";
import { IsValidEasySeatData } from "helpers/filter";
import { CCard, CCardBody } from "@coreui/react-pro";
import FontAwesome from "react-fontawesome";
import $ from "jquery";
import { renderToString } from "react-dom/server";
import SndUnknown from "assets/sounds/Unknown.mp3";
import SndLightBuddy from "assets/sounds/LightBuddy.mp3";
import SndBuddy from "assets/sounds/Buddy.mp3";
import SndMegaBuddy from "assets/sounds/MegaBuddy.mp3";
import SndUltraBuddy from "assets/sounds/UltraBuddy.mp3";
import { RemoveSpaceInText } from "helpers/filter";
import {
  LIGHTBUDDY,
  MEGABUDDY,
  ULTRABUDDY,
  BUDDY,
  UNKNOWN,
  TT_BP,
} from "constant/constant";
import {
  supaClient,
  GetPlayersBynIDs,
  GetPlayersByNickname,
} from "helpers/supabase";
import useSound from "use-sound";

const DashboardPage = () => {

  const [playUnknown] = useSound(SndUnknown, { interrupt: true });
  const [playLightBuddy] = useSound(SndLightBuddy, { interrupt: true });
  const [playBuddy] = useSound(SndBuddy, { interrupt: true });
  const [playUltraBuddy] = useSound(SndUltraBuddy, { interrupt: true });
  const [playMegaBuddy] = useSound(SndMegaBuddy, { interrupt: true });

  const pColors = useSelector((state) => state.playerColorState.playerColors);
  const dispatch = useDispatch();
  const profiles = useSelector((x) => x.profileState.profiles);
  const realStacks = useSelector((x) => x.stackConvertorState.data);
  const siteUnionsClubs = useSelector((x) => x.siteUnionClubState.data);
  const soundOnOff = useSelector((state) => state.changeState.soundOnOff);

  const curProfileIndex = useSelector(
    (x) => x.profileState.currentProfileIndex
  );

  if (profiles.length === 0) {
    return (
      <CCard className="mb-2 mt-2">
        <CCardBody
          style={{ background: "darkred", borderRadius: "6px", color: "white" }}
        >
          <h4>
            <FontAwesome
              name="triangle-exclamation"
              size="lg"
              style={{ color: "yellow" }}
            ></FontAwesome>{" "}
            You can never any clubs now. Please have a contact with
            adminstrator.{" "}
          </h4>
        </CCardBody>
      </CCard>
    );
  }

  let datas = [];

  useEffect(() => {
    
    if (history.location.pathname.includes("dashboard")) {
      dispatch({ type: "set", showProfile: true });
    } else {
      dispatch({ type: "set", showProfile: false });
    }

    const playSound = async (playertype) => {
      if (!soundOnOff) return;
      try {
        console.log("trigger to play sound")
        switch (playertype) {
          case UNKNOWN:
            await playUnknown();
            break;
          case LIGHTBUDDY:
            await playLightBuddy();
            break;
          case BUDDY:
            await playBuddy();
            break;
          case ULTRABUDDY:
            await playUltraBuddy();
            break;
          default:
            await playMegaBuddy();
            break;
        }
      } catch (error) {
        console.error("Error playing sound:", error);
      }
    };

    const taskListener = supaClient
      .channel("public:easyseat_data")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "easyseat_data" },
        (payload) => {
          if (payload.new) {
            let new_data = GetEasySeatJSONObject(payload.new);

            GetPlayersBynIDs(new_data["site"], new_data["nids"]).then(
              (rval) => {
                // console.log(rval);
                if (rval === null) return;
                
                rval.forEach((ele) => {
                  new_data["playertypes"][ele["nid"]] = ele["playertype"];
                });

                if (new_data["playertype"] !== "") {
                  if (new_data["playertype"] !== null) {
                    if (
                      new_data["playertypes"][new_data["nid"]] === UNKNOWN
                    ) {
                      new_data["playertypes"][new_data["nid"]] =
                        new_data["playertype"];
                    }
                  }
                }
                if (
                  IsValidEasySeatData(
                    new_data,
                    profiles[curProfileIndex],
                    siteUnionsClubs,
                    realStacks
                  )
                ) {
                  RemoveUnnessaryTableType(new_data);
                  playSound(new_data["playertypes"][new_data["nid"]])
                  AppendEasySeatDataRow(new_data);
                }
              }
            );
          }
        }
      )
      .subscribe();
    return () => {
      taskListener.unsubscribe();
    };
  }, [
    dispatch,
    supaClient,
    siteUnionsClubs,
    realStacks,
    profiles,
    curProfileIndex,
    soundOnOff,
    pColors,
    playUnknown, 
    playLightBuddy, 
    playBuddy, 
    playUltraBuddy, 
    playMegaBuddy
  ]);

  const AppendEasySeatDataRow = (data) => {
    const parentDiv = $(".easyseat-dashboard-content");
    if (datas.length === 0) {
      parentDiv.css({ border: "1px solid #c1bfbf" });
    }
    const childDiv = renderToString(GetEasySeatDataRow(data));
    parentDiv.append(childDiv);
    parentDiv.animate({ scrollTop: 1000 }, "slow");
    $("html, body").scrollTop($(document).height());
    datas.push(data);
  };

  const GetEasySeatDataRow = (a) => {
    return (
      <EasySeatItemRowWidgetNew
        key={a["id"]}
        a={a}
        pt={profiles[curProfileIndex]["playertype"]}
        minPlayerType={profiles[curProfileIndex]["min_player_type"]}
        pColors={pColors}
        siteUnionClubs={siteUnionsClubs}
        soundOnOff={soundOnOff}
      />
    );
  };

  const RemoveUnnessaryTableType = (incoming_data) => {
    var updated_table_type = [];
    var isNoExistBP = false;

    if (
      incoming_data["bombpothand"] === null ||
      incoming_data["bombpothand"] === undefined
    ) {
      isNoExistBP = true;
    }
    if (incoming_data["bombpothand"] === "") {
      isNoExistBP = true;
    }

    incoming_data["tabletype"].forEach((ele) => {
      var isflag = false;
      profiles[curProfileIndex]["tabletype"].forEach((ele1) => {
        if (RemoveSpaceInText(ele1.text) === RemoveSpaceInText(ele)) {
          isflag = true;
        }
      });
      if (isflag) {
        updated_table_type.push(ele);
        isflag = false;
      }
    });
    if (!isNoExistBP) {
      updated_table_type.push(TT_BP);
    }
    incoming_data["tabletype"] = updated_table_type;
  };

  return <div className="align-items-center easyseat-dashboard-content">
    
  </div>;
};
export default React.memo(DashboardPage);
