import React, { useState } from "react";
import {
  CCard,
  CCardHeader,
  CRow,
  CCol,
  CCardBody,
  CSpinner,
} from "@coreui/react-pro";
import FontAwesome from "react-fontawesome";
import AccessableTreeviewWidget from "./widgets/AccessableTreeviewWidget";


const UserAccessPage = () => {

  const [loading, SetLoading] = useState(false);

  const getMainElement = () => {
    if (!loading) {
      return (
        <div className="align-items-center">
          <CRow>
            <CCol lg={12}>
              <CCard className="mb-1 mt-1">
                <CCardHeader>
                  <FontAwesome name="dice" />
                  <strong> Sites, Unions and Clubs</strong>
                </CCardHeader>
                <CCardBody className="easyseat-text-align-left">{<AccessableTreeviewWidget />}</CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
      );
    } else {
      return (
        <div className="d-flex p-3">
          <CSpinner color="danger" variant="grow" className="m-1" />
          <CSpinner color="danger" variant="grow" className="m-1" />
          <CSpinner color="danger" variant="grow" className="m-1" />
        </div>
      );
    }
  };

  return (
    <>{getMainElement()}</>
  );
};
export default React.memo(UserAccessPage);
