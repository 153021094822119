import React, { useEffect } from "react";
import useSound from "use-sound";
import SndUnknown from "assets/sounds/Unknown.m4a";
import SndLightBuddy from "assets/sounds/LightBuddy.m4a";
import SndBuddy from "assets/sounds/Buddy.m4a";
import SndMegaBuddy from "assets/sounds/MegaBuddy.m4a";
import SndUltraBuddy from "assets/sounds/UltraBuddy.m4a";

import {
  UNKNOWN,
  LIGHTBUDDY,
  BUDDY,
  MEGABUDDY,
  ULTRABUDDY,
} from "constant/constant";

const PlaySoundUse = ({ playertype, soundOnOff }) => {
  console.log("Player Type:", playertype);
  console.log("Sound On/Off:", soundOnOff);

  const [playUnknown] = useSound(SndUnknown, { interrupt: true });
  const [playLightBuddy] = useSound(SndLightBuddy, { interrupt: true });
  const [playBuddy] = useSound(SndBuddy, { interrupt: true });
  const [playUltraBuddy] = useSound(SndUltraBuddy, { interrupt: true });
  const [playMegaBuddy] = useSound(SndMegaBuddy, { interrupt: true });

  useEffect(() => {
    console.log("Effect triggered");

    const playSound = async () => {
      if (!soundOnOff) return;
      try {
        switch (playertype) {
          case UNKNOWN:
            await playUnknown();
            break;
          case LIGHTBUDDY:
            await playLightBuddy();
            break;
          case BUDDY:
            await playBuddy();
            break;
          case ULTRABUDDY:
            await playUltraBuddy();
            break;
          default:
            await playMegaBuddy();
            break;
        }
      } catch (error) {
        console.error("Error playing sound:", error);
      }
    };

    playSound();
  }, [
    playertype, 
    soundOnOff, 
    playUnknown, 
    playLightBuddy, 
    playBuddy, 
    playUltraBuddy, 
    playMegaBuddy
  ]);

  return <div style={{ display: 'none' }}></div>;
}

export default React.memo(PlaySoundUse);