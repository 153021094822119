import React, { useState } from "react";
import { CRow, CCol, CFormLabel } from "@coreui/react-pro";
import { GetRealStakeWithDollar } from "../../helpers/trasform";
import axios from 'axios';
import {
  DIMENTION_IPAD,
  UNKNOWN,
  LIGHTBUDDY,
  BUDDY,
  MEGABUDDY,
  ULTRABUDDY,
  REGULAR,
  TG_RED,
  TG_ORANGE,
  TG_YELLOW,
  TG_GREEN,
  TG_BLUE,
  TG_PURPLE,
  TG_BROWN,
  TG_WHITE,
  TG_BLACK,
} from "constant/constant";

const EasySeatAlertForTelegram = ({ a, pt, minPlayerType, siteUnionClubs, telegramSetting }) => {

  const getEnableMinPlayerTypes = () => {
    let ret = [];
    if (minPlayerType[UNKNOWN] > 1) {
      ret.push(UNKNOWN);
    }
    if (minPlayerType[LIGHTBUDDY] > 1) {
      ret.push(LIGHTBUDDY);
    }
    if (minPlayerType[BUDDY] > 1) {
      ret.push(BUDDY);
    }
    if (minPlayerType[MEGABUDDY] > 1) {
      ret.push(MEGABUDDY);
    }
    if (minPlayerType[ULTRABUDDY] > 1) {
      ret.push(ULTRABUDDY);
    }
    return ret;
  }

  let enableMinPlayerTypes = getEnableMinPlayerTypes();

  const GetCurrency = () => {
    let ret = "$";

    siteUnionClubs.forEach((element) => {
      if (element.site_name === a.site) {
        ret = element.site_currency;
      }
    });

    return ret;
  };

  const getShowAbleNids = () => {
    let ret = [];
    let unret = [];

    a["nids"].forEach(ele => {
      if (enableMinPlayerTypes.includes(a['playertypes'][ele])) {
        if (!(a['playertypes'][ele] === "regular" || !pt[a['playertypes'][ele]])) {
          ret.push(ele);
        }
      } else {
        if (!(a['playertypes'][ele] === "regular" || !pt[a['playertypes'][ele]])) {
          unret.push(ele);
        }
      }
    });

    const playersCount = Math.min(a["nids"].length, 3);
    ret = ret.slice(0, playersCount);
    unret.forEach(ele => {
      if (ret.length < 3) {
        ret.push(ele);
      }
    });
    return ret;
  }

  let ptBlocks = getShowAbleNids();

  const GetValidClubName = (clubname) => {
    let length = 20;
    if (!clubname) return null;
    if (clubname.length > length) {
      return clubname.substring(0, length) + "..";
    } else {
      return clubname;
    }
  };

  const GetValidNickName = (nickname) => {
    let length = 9;
    if (nickname.length > length) {
      return nickname.substring(0, length) + ".";
    } else {
      return nickname;
    }
  };

  const GetValidTableName = (tablename) => {
    let length = 20;
    if (tablename.length > length) {
      return tablename.substring(0, length) + "..";
    } else {
      return tablename;
    }
  };


  const message = `
    <b>🚀 EasySeat Alert 🚀</b>\n
    <b>🕒 20:53:20</b>  🟥🟧🟨🟩🟦🟪⬛
    💰 <b>$0.25/0.50 (0.5/1)</b>  
    🎲 <b>PLO5</b>  
    🏆 <b>Z-Capital</b>  
    💵 <b>20-100 JP</b>  
    👥 <b>(2/6)</b> AzzaAus, DnM01
    `;

  const GetTeleNickNames = (nid, type) => {
    if (type === "regular") return;
    if (!pt[type]) return;
    var nickname = GetValidNickName(a["nicknames"][a["nids"].indexOf(nid)]);
    return nickname;
  };

  const GetTGPlayerTypeBlock = (playerType) => {
    if (playerType === "regular") return;
    if (!pt[playerType]) return;

    let typeIndex = 0;
    switch (playerType) {
      case REGULAR:
        typeIndex = 0;
        break;
      case UNKNOWN:
        typeIndex = 1;
        break;
      case LIGHTBUDDY:
        typeIndex = 2;
        break;
      case BUDDY:
        typeIndex = 3;
        break;
      case MEGABUDDY:
        typeIndex = 4;
        break;
      case ULTRABUDDY:
        typeIndex = 5;
        break;
    }
    let tcolors = telegramSetting.telegram_colors.split(",");
    let colorIndex = parseInt(tcolors[typeIndex]);
    switch (colorIndex) {
      case 0:
        return TG_RED;
      case 1:
        return TG_ORANGE;        
      case 2:
        return TG_YELLOW;
      case 3:
        return TG_GREEN;
      case 4:
        return TG_BLUE;
      case 5:
        return TG_PURPLE;
      case 6:
        return TG_BROWN;
      case 7:
        return TG_WHITE;
      case 8:
        return TG_BLACK;
    }
  }

  const GetTelegramMessage = (data) => {
    // console.log(data);
    let playerletters = []
    let players = []
    ptBlocks.forEach((r) => {
      players.push(r);
      playerletters.push(GetTGPlayerTypeBlock(data["playertypes"][r]))
    });
    let pletter = '';
    for (let i = 0; i < playerletters.length; i++) {
      pletter += playerletters[i];
    }

    let tletter = '';
    for (let i = 0; i < data["tabletype"].length; i++) {
      let ttletter = '';
      if(data["tabletype"][i] == "BombPot"){
        ttletter = `${data["tabletype"][i]} (1/${data["bombpothand"]})`;
      }else{
        ttletter = data["tabletype"][i];
      }
      if (i === data["tabletype"].length - 1) {
        tletter += ttletter;
      } else {
        tletter += ttletter + ', ';
      }
    }

    let pyletter = '';
    for (let i = 0; i < players.length; i++) {
      if (i === players.length - 1) {
        pyletter += GetTeleNickNames(players[i], data["playertypes"][players[i]]);
      } else {
        pyletter += GetTeleNickNames(players[i], data["playertypes"][players[i]]) + ', ';
      }
    }

    const message = `
      <b>🚀 EasySeat Alert 🚀</b>\n
 🐳: ${pletter}
 💵: <b>${data.realstack === "" ? `${GetCurrency()}${data["blinds"]}` : `${GetRealStakeWithDollar(data.realstack, GetCurrency())}(${data["blinds"]})`}</b>
 🏛️: <b>${a["site"]}</b> - <b>${data["club"]}</b>
 🎲: <b>${data["gametype"]}</b>
 🛠️: <b>${tletter}</b>
 🃏: <b>${a["tablename"]}</b>
 👥: <b>Players(${data["positions"].length}/${data["maxtablesize"]}):</b>  <b>${pyletter}</b>`;

    return message;
  }
  // Function to send the message
  const sendTelegramMessage = async (data) => {
    try {
      if (telegramSetting.telegram_alert === "1") return false;
      if (telegramSetting.telegram_token === "1") return false;
      let message = GetTelegramMessage(data);
      const url = `https://api.telegram.org/bot${telegramSetting.telegram_token}/sendMessage`;
      const response = await axios.post(url, {
        chat_id: telegramSetting.telegram_chatid,
        text: message,
        parse_mode: 'HTML'
      });

      if (response.data.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error sending message:', error);
      return false;
    }
  };
  sendTelegramMessage(a);
  return (<CRow></CRow>);
};
export default React.memo(EasySeatAlertForTelegram);
