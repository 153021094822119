
const initialState = {
  telegramSetting:{
    id:1,
    user_nid:"1",
    telegram_name:"",
    telegram_alert:"1",
    telegram_chatid:"",
    telegram_token:"",
    telegram_admin:"1",
    telegram_colors:"5,3,2,4,1,0"
  }
  
}
  
export const telegramSettingReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'set_telegram_setting':
        return {
          ...state,
          telegramSetting : action.payload,
      }
      default:
        return state
    }
}