
const initialState = {
  telegramToken:""  
}
  
export const telegramTokenReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'set_telegram_token':
        return {
          ...state,
          telegramToken : action.payload,
      }
      default:
        return state
    }
}