import ProfileSettingPage from "./ProfileSettingPage";
import DashboardPage from "./DashboardPage";
import AddRemovePlayerPage from "./AddRemovePlayerPage";
import PlayerColorsPage from "./PlayerColorsPage";
import ProfileSettingPageNew from "./ProfileSettingPageNew";

export {
  AddRemovePlayerPage,
  ProfileSettingPage,
  PlayerColorsPage,
  DashboardPage,
  ProfileSettingPageNew,
};
