import { createClient } from "@supabase/supabase-js";
import {
  SUPABASE_KEY,
  SUPABASE_URL,
  SUPA_USER_TABLE,
  SUPA_PLAYER_COLOR_TABLE,
  SUPA_PLAYER_TABLE,
  SUPA_PROFILE_TABLE,
  SUPA_EASYSEAT_DATA,
} from "../constant/constant";
import { ConvertLowercase } from "./utils";

// Instance of client to connect the supabase backend
export const supaClient = createClient(SUPABASE_URL, SUPABASE_KEY);

// function to get the user info from supbase backend's easyseat_user table when login
export async function GetLoginUserInfor(username, password) {
  try {
    const { data, error } = await supaClient
      .from(SUPA_USER_TABLE)
      .select("*")
      .eq("username", username)
      .eq("password", password);
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to delete player info to supbase backend's easyseat_player table
export async function ClearEasySeatDataOnSupabase() {
  try {
    const { error } = await supaClient
      .from(SUPA_EASYSEAT_DATA)
      .delete()
      .neq("id", "-1");
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

export async function DeletePlayerBynID(player) {
  try {
    const { error } = await supaClient
      .from(SUPA_PLAYER_TABLE)
      .delete()
      .eq("id", player["id"]);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

export async function DeletePlayerByID(user) {
  try {
    const { error } = await supaClient
      .from(SUPA_PLAYER_TABLE)
      .delete()
      .eq("id", user["id"]);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

// Function to update player's colors info of supbase backend's easyseat_player_color table
export async function UpdatePlayerByID(player) {
  try {
    const { error } = await supaClient
      .from(SUPA_PLAYER_TABLE)
      .update(player)
      .eq("id", player.id);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

// Function to insert new player info to supbase backend's easyseat_playertable
export async function InsertNewPlayer(data) {
  try {
    const { error } = await supaClient.from(SUPA_PLAYER_TABLE).insert(data);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

/** EasySeat Player Color */

// Function to get player's colors info from supbase backend's easyseat_player_color table
export async function GetPlayerColors(user_id) {
  try {
    const { data, error } = await supaClient
      .from(SUPA_PLAYER_COLOR_TABLE)
      .select("*")
      .eq("user_nid", user_id);
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to insert player's colors info to supbase backend's easyseat_player_color table
export async function InsertPlayerColors(data) {
  try {
    const { error } = await supaClient
      .from(SUPA_PLAYER_COLOR_TABLE)
      .insert(data);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

// Function to update player's colors info of supbase backend's easyseat_player_color table
export async function UpdatePlayerColors(user_nid, data) {
  try {
    const { error } = await supaClient
      .from(SUPA_PLAYER_COLOR_TABLE)
      .update(data)
      .eq("user_nid", user_nid);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

/** EasySeat Players */

// Function to get player info from supbase backend's easyseat_player table
export async function GetPlayersBynIDs(site, nids) {
  try {
    const { data, error } = await supaClient
      .from(SUPA_PLAYER_TABLE)
      .select("*")
      .eq("site", site)
      .in("nid", nids);

    if (error) {
      console.log(error);
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

export async function GetPlayersByNickname(site, nickname) {
  try {
    // var tmp = [];
    // nickname.forEach((element) => {
    //   tmp.push(ConvertLowercase(element))
    // });

    const { data, error } = await supaClient
      .from(SUPA_PLAYER_TABLE)
      .select("*")
      .eq("site", site)
      .in("nid", tmp);

    if (error) {
      console.log(error);
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

export async function GetPlayer(user) {
  try {
    const { data, error } = await supaClient
      .from(SUPA_PLAYER_TABLE)
      .select("*")
      .eq("nid", user["nid"])
      .eq("site", user["site"]);

    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

export async function GetPlayerBynID(user) {
  try {
    const { data, error } = await supaClient
      .from(SUPA_PLAYER_TABLE)
      .select("*")
      .eq("nid", user["nid"])
      .eq("site", user["site"]);
    if (error) {
      console.log(error);
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to delete player info to supbase backend's easyseat_player table
export async function DeletePlayer(user) {
  try {
    const { error } = await supaClient
      .from(SUPA_PLAYER_TABLE)
      .delete()
      .eq("nid", user["nid"])
      .eq("site", user["site"]);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

/** EasySeat Profiles */

// Function to get player's profiles info from supbase backend's easyseat_profile table
export async function GetProfiles(user_id) {
  try {
    const { data, error } = await supaClient
      .from(SUPA_PROFILE_TABLE)
      .select("*")
      .eq("user_nid", user_id);
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

// Function to insert player's profiles info to supbase backend's easyseat_profile table
export async function InsertProfile(data) {
  try {
    const { error } = await supaClient.from(SUPA_PROFILE_TABLE).insert(data);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

// Function to update player's profile info of supbase backend's easyseat_profile table
export async function UpdateProfile(user_nid, data) {
  try {
    const { error } = await supaClient
      .from(SUPA_PROFILE_TABLE)
      .update(data)
      .eq("user_nid", user_nid)
      .eq("name", data.name);
    if (error) {
      console.log(error);
      return -1;
    }
    return 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
}

// Function to delete profile info to supbase backend's easyseat_profile table
export async function DeleteProfile(data) {
  try {
    const { error } = await supaClient
      .from(SUPA_PROFILE_TABLE)
      .delete()
      .eq("user_nid", data["user_nid"])
      .eq("name", data["name"]);
    if (error) {
      console.log(error);
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getAdminSetting() {
  try {
    const supaAdmin = createClient(SUPABASE_KEY, SUPABASE_URL);
    const { data, error } = await supaAdmin.from(ADMIN_TABLE).select("*");
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

export async function insertAdminSetting(setting) {
  try {
    const supaAdmin = createClient(SUPABASE_KEY, SUPABASE_URL);
    const { error } = await supaAdmin.from(ADMIN_TABLE).insert(setting);
    if (error) {
      return -1;
    }
    return 1;
  } catch (error) {
    return 1;
  }
}

export async function updateAdminSetting(setting) {
  try {
    const supaAdmin = createClient(SUPABASE_KEY, SUPABASE_URL);
    const { error } = await supaAdmin
      .from(ADMIN_TABLE)
      .update(setting)
      .eq("id", 1);
    if (error) {
      return -1;
    }
    return 1;
  } catch (error) {
    return 1;
  }
}

export async function getTableData(supaURL, supaKEY, tableName) {
  try {
    const supaSriChart = createClient(supaURL, supaKEY);
    const { data, error } = await supaSriChart.from(tableName).select("*");
    if (error) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
}

export function getRTSupaClient() {
  try {
    const supaRT = createClient(SUPABASE_URL, SUPABASE_KEY);
    return supaRT;
  } catch (error) {
    return null;
  }
}
