import PlaySoundUse from "components/subcomponents/PlaySoundUse";
import { BUDDY } from "constant/constant";
import React from "react";

export default function AppTest() {
  const handleToggle = () => {
    return (<div>
      {PlaySoundUse(BUDDY, true)}
    </div>)
  }
  return (
    <div>
      {PlaySoundUse(BUDDY, true)}
      {/* <button style={{height:'40px', width:'240px'}} onClick={handleToggle}>
        Click Me
      </button> */}
    </div>
  );
}
