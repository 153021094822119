import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";
import { TELEGRAM_BOT_TOKEN } from "constant/constant";
import { GetTelegramAdminSetting } from "helpers/admin/supabase";

const TestPage = () => {

  const telegramSetting = useSelector((state) => state.telegramSettingState.telegramSetting);
  // Function to get the chat ID
  const getChatId = async () => {
    if (telegramSetting.telegram_token === "") return;
    try {
      const url = `https://api.telegram.org/bot${telegramSetting.telegram_token}/getUpdates`;
      const response = await axios.get(url);
      if (response.data.ok && response.data.result.length > 0) {
      } else {
      }
    } catch (error) {
      console.error('Error getting updates:', error);
    }
  };
  // Function to send the message
  const sendMessage = async (message) => {
    
    if (telegramSetting.telegram_chatid === "") return;
    if (telegramSetting.telegram_token === "") return;
    try {
      const url = `https://api.telegram.org/bot${telegramSetting.telegram_token}/sendMessage`;
      const response = await axios.post(url, {
        chat_id: telegramSetting.telegram_chatid,
        text: message,
        parse_mode: "HTML",
      });

      if (response.data.ok) {
      } else {
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // const GetTelegramMessage = () => {
  //   const message = `
  //   <b>Game Alert 🚀</b>
  //   <i>Time:</i> <u>20:53:20 🍸</u>
  //   <b>Stakes:</b> $0.25/0.50(0.5/1) 
  //   <b>Game Type:</b> <code>PLO5 🍸</code>
  //   <b>Table:</b> Z-Capital\n
  //   <b>Players:</b> (2/6) AzzaAus, DnM01
  //   <a href="https://yourgame.com">🔗 Join Now</a>
  // `;
  //   return message;
  // }

  const message = `
  <b>🚀 EasySeat Alert 🚀</b>\n
  <b>🕒 20:53:20</b>  🟥🟧🟨🟩🟦🟪⬛
  💰 <b>$0.25/0.50 (0.5/1)</b>  
  🎲 <b>PLO5</b>  
  🏆 <b>Z-Capital</b>  
  💵 <b>20-100 JP</b>  
  👥 <b>(2/6)</b> AzzaAus, DnM01
  `;

  const GetTelegramMessage = () => {
    const message = `
    <b>🚀 EasySeat Alert 🚀</b>\n
    <b>Time:</b> <u>20:53:20 🍸</u>  <b>Stakes:</b> <code>$0.25/0.50(0.5/1)</code>\n
    <b>GameType:</b> <code>PLO5 🍸</code> <b>Table:</b> <code>Z-Capital</code>\n
    <b>Players (2/6):</b> <code>AzzaAus, DnM01</code>
  `;
    return message;
  }

  // Trigger the message send action after getting the chat ID
  useEffect(() => {
    getChatId();
    sendMessage(message);
    // Send a test message after fetching the chat ID
  }, [telegramSetting]); // Only runs when `chatId` changes

  return (
    <div>
      <h1>Test Page</h1>
    </div>
  );
};

export default React.memo(TestPage);
