import ProfileSettingPage from "./ProfileSettingPage";
import DashboardPage from "./DashboardPage";
import AddRemovePlayerPage from "./AddRemovePlayerPage";
import PlayerColorsPage from "./PlayerColorsPage";
import ProfileSettingPageNew from "./ProfileSettingPageNew"
import AppTest from "./AppTest"
export {
  AddRemovePlayerPage,
  ProfileSettingPage,
  PlayerColorsPage,
  DashboardPage,
  ProfileSettingPageNew,
  AppTest,
};
