export const SET_REAL_TEST = "SET_REAL_TEST";
export const PASSWORD_LENGTH = 4;

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGUPDATE = "LOGUPDATE";

// supabase url and key
export const SUPABASE_URL = "https://xfospiidchxnhsjfjlpk.supabase.co";
export const SUPABASE_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inhmb3NwaWlkY2h4bmhzamZqbHBrIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTgxOTA0NDQsImV4cCI6MjAxMzc2NjQ0NH0.ypY0J59TW8MDXoztQ7I0k-2B6OVId-3Vo7x_xwDFglM";

// supabase tables's names
export const SUPA_USER_TABLE = "easyseat_user";
export const SUPA_PLAYER_COLOR_TABLE = "easyseat_player_color";
export const SUPA_PLAYER_TABLE = "easyseat_player";
export const SUPA_EASYSEAT_DATA = "easyseat_data";
export const SUPA_PROFILE_TABLE = "easyseat_profile";
export const SUPA_SITES_UNIONS_CLUBS_TABLE = "easyseat_sites_unions_clubs";
export const SUPA_ACCESS_SITE_TABLE = "easyseat_user_access";
export const SUPA_STACK_CONVERTOR_TABLE = "easyseat_stack_convertor";
export const TYPE_ADMIN = "0";
export const TYPE_USER = "1";

export const DIMENTION_IPAD = 1400;
// min megabuddy and buddy options
export const MIN_OPTIONS = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
];

export const EMPYSEATS = [
  { value: 0, label: "OFF" },
  { value: 1, label: "0" },
  { value: 2, label: "1" },
  { value: 3, label: "2" },
  { value: 4, label: "3" },
  { value: 5, label: "4" },
  { value: 6, label: "5" },
];

export const MIN_PLYAER_TYPE = [
  { value: 1, label: "OFF" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
];

// player type options for selector
export const PLAYER_TYPES_OPTIONS = [
  { value: "regular", label: "Regular" },
  { value: "lightbuddy", label: "Light Buddy" },
  { value: "buddy", label: "Buddy" },
  { value: "megabuddy", label: "Mega Buddy" },
  { value: "ultrabuddy", label: "Ultra Buddy" },
];

// player type
export const UNKNOWN = "unknown";
export const REGULAR = "regular";
export const LIGHTBUDDY = "lightbuddy";
export const BUDDY = "buddy";
export const MEGABUDDY = "megabuddy";
export const ULTRABUDDY = "ultrabuddy";

export const PLAYER_TYPES = {
  unknown: 0,
  lightbuddy: 1,
  buddy: 2,
  megabuddy: 3,
  ultrabuddy: 4,
};

// site
export const PPP = "PPPoker";
export const CLUBGG = "ClubGG";
export const WINAMAX = "Winamax";
export const POKERSTARS = "PokerStars";
export const S888POKER = "888Poker";
export const AMERICASCARDROOM = "Americas Cardroom"

// user add/remove site page
export const SITE = "SITE";
export const UNION = "UNION";
export const CLUB = "CLUB";
export const HIDDEN = "HIDDEN";

// data count
export const DATA_CNT = 300;

//table type
export const TT_VPIP = "VPIP";
export const TT_DB = "Double Board";
export const TT_CallTIME = "CallTime";
export const TT_BP = "BombPot";
export const TT_BP1 = "BombPot 1/1";
export const TT_BP3 = "BombPot 1/3";
export const TT_BP5 = "BombPot 1/5";
export const TT_BP7 = "BombPot 1/7+";

//game type
export const PLO = "PLO";
export const PLO5 = "PLO5";
export const PLO6 = "PLO6";
export const NLHE = "NLHE";

// blinds options
export const BLINDS = [
  "0.05/0.1",
  "0.25/0.5",
  "0.5/1",
  "1/2",
  "2/4",
  "3/6",
  "5/10",
  "7.5/15",
  "10/20",
  "15/30",
  "20/40",
  "25/50",
  "30/60",
  "40/80",
  "50/100",
  "100/200",
  "200/400",
];
