import React, { useEffect, useState } from "react";
import { CFormLabel, CFormSelect } from "@coreui/react-pro";
import { PLAYER_TYPES_OPTIONS } from "constant/constant";
import { GetLogoImgElement } from "helpers/utils";
import PlayerTypeFormSelect from "./PlayerTypeFormSelect";
import { UpdatePlayerByID } from "helpers";
import { ShowSuccessToast, ShowFailToast } from "helpers/toast-alert";

const PlayerDetailsForm = ({ player, searchTerm, setSearchTerm }) => {
  // Check if a player is selected
  if (!player) {
    return (
      <div className="easyseat-playerdetails-form">
        Select a player to view details.
      </div>
    );
  }

  // Handle changes to the player type select
  const addPlayerTypeOnChange = (event) => {
    player.playertype = event;
    UpdatePlayerByID(player).then((ret) => {
      if (ret === 1) {
        ShowSuccessToast(
          `Updating Success! ${player.nickname}'s playertype has been updated.`
        );
        // let st = searchTerm;
        setSearchTerm("");
      } else {
        ShowFailToast(
          "Updating this player's type Failed! Please check again."
        );
      }
    });
  };

  // Function to get the calculated value
  const getDividedValueInstByOpp = (inst, opp) => {
    if (inst === null || opp === null) {
      return "-";
    }
    return Math.round((inst * 100) / opp, 2);
  };

  const getColorNetBb100 = () => {
    if (player.stat_netbb100) {
      return player.stat_netbb100 < 0 ? "red" : "green";
    } else {
      return "white";
    }
  };

  const getNotedbyName = () => {
    if (player.setbyuser) {
      return "User";
    } else {
      return "Autonote";
    }
  };

  const getKTypeValue = (value) =>{
    if(Math.abs(value) < 1000){
      return value;
    }else{
      return Math.round((value / 1000), 2) + 'k'
    }
  }

  return (
    <div className="easyseat-playerdetails-form">
      <div
        style={{ marginLeft: "12px", marginTop: "12px", marginBottom: "8px" }}
      >
        {GetLogoImgElement(player.site)}
        <CFormLabel>{player.nickname}</CFormLabel>
      </div>
      <PlayerTypeFormSelect
        options={PLAYER_TYPES_OPTIONS} // Ensure options is an array of objects with value and label
        value={player.playertype}
        onChange={addPlayerTypeOnChange}
      />
      <div style={{ display: "flex", margin: "12px 4px 4px 16px" }}>
        <div style={{ margin: "4px", width: "45%" }}>
          VPIP:{" "}
          <strong>
            {getDividedValueInstByOpp(
              player.stat_vpip_inst,
              player.stat_vpip_opp
            )}
          </strong>
        </div>
        <div style={{ margin: "4px", width: "45%" }}>
          PFR:{" "}
          <strong>
            {getDividedValueInstByOpp(
              player.stat_rfi_inst,
              player.stat_rfi_opp
            )}
          </strong>
        </div>
      </div>
      <div style={{ display: "flex", margin: "4px 4px 4px 16px" }}>
        <div style={{ margin: "4px", width: "45%" }}>
          3bet:{" "}
          <strong>
            {getDividedValueInstByOpp(player.stat_3b_inst, player.stat_3b_opp)}
          </strong>
        </div>
        <div style={{ margin: "4px", width: "45%" }}>
          Hands:{" "}
          <strong>
            {getKTypeValue(player.stat_hands)}
          </strong>
        </div>
      </div>
      <div style={{ display: "flex", margin: "4px 4px 4px 16px" }}>
        <div style={{ margin: "4px 4px 4px 32px", color: getColorNetBb100() }}>
          <strong>{!player.stat_netbb100 ? "-" : getKTypeValue(player.stat_netbb100)}</strong>
        </div>
        <div style={{ margin: "4px", width: "45%" }}>
          <strong>bb/100</strong>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          margin: "12px 4px 4px 16px",
          fontWeight: 500,
          color: "#fff99f",
        }}
      >
        Note created By : {getNotedbyName()}
      </div>
    </div>
  );
};

export default React.memo(PlayerDetailsForm);
