import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react-pro";
import { useDispatch, useSelector } from "react-redux";
import {
  DashboardPage,
  AddRemovePlayerPage,
  PlayerColorsPage,
  ProfileSettingPageNew,
} from "./usersubpages";
import {
  AddRemoveSitePage,
  AddRemoveUserPage,
  StackConvertorPage,
  ServerStatusPage,
} from "./adminsubpages";
import { GetPlayerColors } from "../helpers/supabase";
import { Page404 } from "pages/404";
import TestPage from "./adminsubpages/TestPage";
import TelegramSettingPage from "./usersubpages/TelegramSettingPage";
import { GetTelegramAdminSetting, GetTelegramSettingByUserId, InsertTelegramSetting, UpdateTelegramSetting } from "helpers/admin/supabase";

const AppContent = () => {
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.login.user[0].id);
  const user = useSelector((state) => state.login.user);

  useEffect(() => {
    if (user) {
      GetPlayerColors(user[0].id)
        .then((data) => {
          if (data.length === 1) {
            dispatch({ type: "set_player_color", payload: data[0] });
          }
        })
        .catch((error) => { });
    }
    GetTelegramSettingByUserId(user_id).then(ret => {
      GetTelegramAdminSetting().then((data) => {
        if (data && data.length !== 0) {
          let setting = {
            user_id: user_id,
            telegram_name: "",
            telegram_alert: "1",
            telegram_token: data[0].telegram_token,
            telegram_chatid: "",
            telegram_colors: "1,2,3,4,5,6",
            telegram_admin: `${user[0].type}`
          }
          if (ret.length === 0) {
            InsertTelegramSetting(setting).then((ret) => {
              dispatch({ type: "set_telegram_setting", payload: setting });
            })
          } else {
            ret[0].telegram_token = data[0].telegram_token;
            UpdateTelegramSetting(ret[0]).then((ele)=>{
              dispatch({ type: "set_telegram_setting", payload: ret[0] });
            })            
          }
        }
      });

    });

  }, [dispatch, user]);

  const GetRoutes = () => {
    if (user && user[0].type === "0") {
      return (
        <Routes>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/profilesetting" element={<ProfileSettingPageNew />} />
          <Route path="/addremoveplayer" element={<AddRemovePlayerPage />} />
          <Route path="/playercolors" element={<PlayerColorsPage />} />
          <Route path="/addremoveuser" element={<AddRemoveUserPage />} />
          <Route path="/addremovesite" element={<AddRemoveSitePage />} />
          <Route path="/stakeconvertor" element={<StackConvertorPage />} />
          <Route path="/serverstatus" element={<ServerStatusPage />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/telegramsetting" element={<TelegramSettingPage />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/profilesetting" element={<ProfileSettingPageNew />} />
          <Route path="/addremoveplayer" element={<AddRemovePlayerPage />} />
          {user[0].telegram_enable === "0" && <Route path="/telegramsetting" element={<TelegramSettingPage />} />}
          <Route path="/playercolors" element={<PlayerColorsPage />} />
          <Route path="*" exact element={<Page404 />} />
        </Routes>
      );
    }
  };

  return (
    <CContainer fluid className="easyseat-align-center-container">
      <Suspense fallback={<CSpinner color="primary" />}>{GetRoutes()}</Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
